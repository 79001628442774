import {EditAdvertActions, EditAdvertState, EditAdvertTypes} from "./types";
import {IAdvertDetail} from "../../models/IAdvert";

const initialState: EditAdvertState = {
    isFetching: false,
    advert: {} as IAdvertDetail,
    data: {},
    fields: [],
    location: null,
    mapLocation: null,
}

const newAdvertReducer = (state: EditAdvertState = initialState, action: EditAdvertActions): EditAdvertState => {
    switch (action.type) {
        case EditAdvertTypes.SET_EDIT_ADVERT_FETCHING: return {...state, isFetching: action.payload}
        case EditAdvertTypes.SET_EDIT_ADVERT_ADVERT: return {...state, advert: action.payload}
        case EditAdvertTypes.SET_EDIT_ADVERT_DATA: return {...state, data: action.payload}
        case EditAdvertTypes.SET_EDIT_ADVERT_FIELDS: return {...state, fields: action.payload}
        case EditAdvertTypes.SET_EDIT_ADVERT_LOCATION: return {...state, location: action.payload}
        case EditAdvertTypes.SET_EDIT_ADVERT_MAP_LOCATION: return {...state, mapLocation: action.payload}
        case EditAdvertTypes.CLEAR_EDIT_ADVERT_STATE: return initialState
        default: return state
    }
}

export default newAdvertReducer