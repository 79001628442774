import React from "react";

const menuItems: TAdminMenuItem[] = [
    {
        title: 'Announcements verification',
        path: '/announcements'
    },
    {
        title: 'Subsection management',
        path: '/subsections'
    },
    {
        title: 'Review management',
        path: '/reviews'
    },
    {
        title: 'Search statistics',
        path: '/search-statistics'
    },
    {
        title: 'Advertising management',
        path: '/adverts',
        subItems: [
            {
                title: 'Advertisers',
                path: '/adverts/advertisers'
            }
        ]
    },
    {
        title: 'User management',
        path: '/users'
    },
    {
        title: 'Setting',
        path: '/settings'
    },
];

export default menuItems

export type TAdminMenuItem = {
    title: string
    path: string,
    subItems?: TAdminMenuItem[]
    component?: React.ComponentType<TAdminMenuItem>
}