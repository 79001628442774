import {createSelector, createSlice, EntityId, PayloadAction, Reducer} from "@reduxjs/toolkit";
import {filtersAdapter} from "./adapters";
import {getSectionsThunk} from "./thunks";
import IAdminSection from "../../../models/IAdminSection";
import IAdminSectionEdit from "../../../models/IAdminSectionEdit";
import {ILang} from "../../../models/ILang";
import IAdminFilter from "../../../models/IAdminFilter";
import {AdminState} from "../store";

interface ISubsections {
    activeSectionUrl?: string,
    items: Array<IAdminSection>
    isFetching: boolean,
    error: string
}


interface SubsectionManagementState {
    subsections: ISubsections
    editSection: IAdminSectionEdit
}

const initialState: SubsectionManagementState = {
    subsections: {
        activeSectionUrl: null,
        items: [],
        isFetching: false,
        error: ""
    },
    editSection: {
        title: {
            en: "",
            sv: "",
            ru: ""
        },
        filters: filtersAdapter.getInitialState()
    }
}

const subsectionManagementSlice = createSlice(
    {
        name: "admin/subsections",
        initialState,
        reducers: {
            setActiveSectionUrl: (state: SubsectionManagementState, action: PayloadAction<string>) => {
                state.subsections.activeSectionUrl = action.payload
            },
            setEditSection: (state: SubsectionManagementState, action: PayloadAction<IAdminSectionEdit>) => {
                state.editSection = action.payload
            },
            setEditSectionTitle: (state: SubsectionManagementState, action: PayloadAction<ILang>) => {
                state.editSection.title = action.payload
            },
            createNewFilter: (state: SubsectionManagementState) => {
                const newFilter: IAdminFilter = {
                    id: state.editSection.filters.ids.length + 5,
                    isNew: true,
                    title: {
                        en: "",
                        sv: "",
                        ru: ""
                    }
                }
                filtersAdapter.addOne(state.editSection.filters, newFilter)
            },
            setFilterData: (state: SubsectionManagementState, action: PayloadAction<{id: EntityId, changes: any }>) => {
                filtersAdapter.updateOne(state.editSection.filters, action.payload)
            }
        },
        extraReducers(builder) {
            builder
            .addCase(getSectionsThunk.pending.type, (state: SubsectionManagementState) => {
                state.subsections.isFetching = true
            })
            .addCase(getSectionsThunk.fulfilled.type, (state: SubsectionManagementState, action: PayloadAction<Array<IAdminSection>>) => {
                state.subsections.items = action.payload
                state.subsections.isFetching = false
            })
            .addCase(getSectionsThunk.rejected.type, (state: SubsectionManagementState, action: PayloadAction<string>) => {
                state.subsections.error = action.payload
                state.subsections.isFetching = false
            })
        }
    }
)

export const getCategories = (state: AdminState) => {
    return state.subsectionManagement.subsections.items.filter(section => section.parent_url === state.subsectionManagement.subsections.activeSectionUrl)
}

export const getBreadcrumbsSelector = createSelector<any, any>(
    (state: AdminState) => state.subsectionManagement.subsections,
    (subsections: ISubsections) => {
        const result = [];
        const activeSectionUrl = subsections.activeSectionUrl
        if (activeSectionUrl) {
            const categoriesMap = new Map(subsections.items.map(category => [category.url, category]))
            let current = categoriesMap.get(activeSectionUrl)
            while (current) {
                result.unshift(current)
                current = categoriesMap.get(current.parent_url)
            }
        }
        return result
    })


export const filtersSelector = filtersAdapter.getSelectors<AdminState>(state => state.subsectionManagement.editSection.filters)

export const SubsectionManagementActions = subsectionManagementSlice.actions
export const reducer = subsectionManagementSlice.reducer as Reducer<SubsectionManagementState>
