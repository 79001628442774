import {combineReducers} from "@reduxjs/toolkit";
import category from "./category/reducer";
import categoryData from "./category-data/reducer"
import filters from "./filters/reducer"
import filtersData from "./filters-data/reducer"
import list from "./list/reducer"
import advert from "./advert/reducer"
import user from "./user/reducer"
import map from "./map/reducer"
import newAdvert from "./new-advert/reducer"
import editAdvert from "./edit-advert/reducer"
import promote from "./promote/slice"
import {reducer as profileMessages} from "./profile-messages/chats";
import {publicApi} from "./api";

export const rootReducer = combineReducers({
  category,
  filters,
  categoryData,
  list,
  filtersData,
  advert,
  user,
  map,
  profileMessages,
  newAdvert,
  editAdvert,
  promote,
  [publicApi.reducerPath]: publicApi.reducer,
});

