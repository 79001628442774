import {AppDispatch} from "../store";
import AxiosActions from "../../axios/axios";
import {ICategory} from "../../models/ICategory";
import CategoryActionCreator from "./actions";

const CategoryThunks = {
    getCategories: (url: string) => async (dispatch: AppDispatch) => {
        dispatch(CategoryActionCreator.setIsFetching(true))
        const response = await AxiosActions.getCategories(url.split("/")[0])
        response.data.sort((a, b) => a.sort - b.sort)
        dispatch(CategoryActionCreator.setCurrentCategory(response.data.find(cat => url === cat.url) || {} as ICategory))
        dispatch(CategoryActionCreator.setAllCategories(response.data))
        dispatch(CategoryActionCreator.setIsFetching(false))
    },
    getRootCategories: () => async (dispatch: AppDispatch) => {
        const response = await AxiosActions.getRootMenu()
        response.data.sort((a, b) => a.sort - b.sort)
        dispatch(CategoryActionCreator.setRootCategories(response.data))
    }
}

export default CategoryThunks