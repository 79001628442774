import {createContext, DetailedHTMLProps, FC, HTMLAttributes, useState} from "react";
import ContactUsPopup from "../components/ContactUsPopup/ContactUsPopup";
import IUser from "../models/IUser";

export const ContactPopupContext = createContext<any>(null);

const ContactPopupProvider: FC<TContactPopupProvider> = (
    {
        children,
        ...restProps
    }) => {

    const [show, setShow] = useState<boolean>(false)

    const showPopup = () => {
        setShow(() => true)
    }
    const hidePopup = () => {
        setShow(() => false)
    }
    return (
        <ContactPopupContext.Provider value={{
            show: showPopup,
            hide: hidePopup
        }}>
            {children}
            <ContactUsPopup  hide={hidePopup} isShowed={show}/>
        </ContactPopupContext.Provider>
    )
}

type TContactPopupProvider = {

} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default ContactPopupProvider