import React from 'react'

import useAlert from "../../hooks/useAlert";
import Portal from "../Portal/Portal";
import styles from "./Alert.module.scss";

const AlertPopup = () => {
    const {text, type} = useAlert();

    return (
        <>
            {
                text && type
                && (
                    <Portal>
                        <div className={styles.container}>
                            <div
                                className={`${styles.alert} ${styles[type]}`}
                            >
                                {text}
                            </div>
                        </div>
                    </Portal>
                )
            }
        </>

    );
};

export default AlertPopup;
