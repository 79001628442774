import {useEffect, useState} from "react";

export enum Resolution {
    IS_MOBILE = "isMobile",
    IS_TABLET = "isTablet",
    IS_BIG_TABLET = "isBigTablet",
    IS_DESKTOP = "isDesktop"
}

const useAdaptive = () => {

    const initial = {
        [Resolution.IS_MOBILE]: false,
        [Resolution.IS_TABLET]: false,
        [Resolution.IS_BIG_TABLET]: false,
        [Resolution.IS_DESKTOP]: false
    }

    const [state, setState] = useState(initial)

    const handleState = (resolution: Resolution) => {
        if (!state[resolution]) {
            setState({...initial, [resolution]: true})
        }
    }

    useEffect(() => {
        const ro = new ResizeObserver(entries => {
            for (let entry of entries) {
                const width = entry.contentBoxSize ? entry.contentBoxSize[0].inlineSize : entry.contentRect.width
                if (width <= 768) {
                    handleState(Resolution.IS_MOBILE)
                } else if (width > 768 && width < 992) {
                    handleState(Resolution.IS_TABLET)
                } else if (width >= 992 && width < 1240) {
                    handleState(Resolution.IS_BIG_TABLET)
                } else {
                    handleState(Resolution.IS_DESKTOP)
                }
            }
        })

        ro.observe(document.querySelector('body'))
    },[])


    return state

};

export default useAdaptive;
