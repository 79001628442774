import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";

export interface PromoteState {
    advertId: string|null
}
const initialState: PromoteState = {
    advertId: null,
}

const promoteSlice = createSlice({
    name: "promote",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<{id: string}>) => {
            state.advertId = action.payload.id
        },
        cleanData: (state) => {
            state.advertId = initialState.advertId
        },
    }
});

export const PromoteActions = promoteSlice.actions;
export default promoteSlice.reducer as Reducer<PromoteState>