export interface IFiltersData {
    [key: string | number]: any
}

export interface FiltersDataState {
    data: IFiltersData
}

export enum FiltersDataActionTypes {
    SET_FILTER_DATA = "SET_FILTER_DATA"
}

export interface SetFiltersDataAction {
    type: FiltersDataActionTypes.SET_FILTER_DATA
    payload: IFiltersData
}

export type FiltersDataActions =
    SetFiltersDataAction