import {createEntityAdapter} from "@reduxjs/toolkit";
import IAdminSection from "../../../models/IAdminSection";
import IAdminFilter from "../../../models/IAdminFilter";

export const subsectionsAdapter = createEntityAdapter<IAdminSection>({
    selectId: subsection => subsection.url
})

export const filtersAdapter = createEntityAdapter<IAdminFilter>({
    selectId: filter => filter.id
})