import {combineReducers} from "@reduxjs/toolkit";
import user from "../user/reducer"
import {reducer as subsectionManagement} from "./subsection-management/slice";
import adverts from "./adverts/slice";
import {api} from "./api";

export const adminReducer = combineReducers({
    user, subsectionManagement, adverts, [api.reducerPath]: api.reducer
})

