import {FC, DetailedHTMLProps, HTMLAttributes, MouseEventHandler} from 'react';
import styles from './AdminSideMenuItem.module.scss';
import {useRouter} from "next/router";


const AdminSideMenuItem: FC<TAdminSideMenuItem> = (
    {
        title,
        path,
        children,
        ...restProps
    }): JSX.Element => {
    const router = useRouter()
    const isActive = router.asPath.startsWith(path)
    const clickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        router.push(path)
    }

    return (
        <div {...restProps} className={styles.container}>
            <a href={path} onClick={clickHandler} className={`${styles.item} ${isActive ? styles.active : ''}`}>{title}</a>
            <div className={styles.submenu}>
                {children}
            </div>
        </div>
    );
};


export default AdminSideMenuItem;


type TAdminSideMenuItem = {
    title: string
    path: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
