import React, {DetailedHTMLProps, FC, HTMLAttributes, KeyboardEventHandler, useState} from 'react';
import styles from './Autocomplete.module.scss';
import Image from "next/legacy/image";


const Autocomplete: FC<TAutocompelete> = (
    {
        items,
        search,
        chooseValue,
        children,
        classDrawer,
        ...restProps
    }): JSX.Element => {

    const [selectedAC, setSelectedAC] = useState<number | null>(null)
    const [focus, setFocus] = useState<boolean>(false)

    const strongSubString = (string: string, subString: string) => {
        const subStringIndex = string?.toLowerCase()?.indexOf(subString?.toLowerCase())
        if (subStringIndex === -1) {
            return string
        }
        const firstPart = string.substring(0, subStringIndex)
        const middlePart = string.substring(subStringIndex, subStringIndex + subString.length)
        const endPart = string.substring(subStringIndex + subString.length, string.length)

        return (
            <>{firstPart}<span className={styles.selected}>{middlePart}</span>{endPart}</>
        )
    }

    const autocompleteRender = (item: TAutoCompleteItem, id: number) => {
        const {title, image} = item
        const formattedString = strongSubString(title, search)

        return (
            <div
                key={id}
                className={`${styles.item} ${id === selectedAC ? styles.selected : ''}`}
                onMouseDown={() => {
                    selectAutoComplete(id)
                }}
            >
                {!!image && <div className={styles.item_icon}>
                    <Image src={image} width={15} height={15} alt={title}/>
                </div>}
                {formattedString}
            </div>
        )
    }

    const keyDownAutocompleteHandler: KeyboardEventHandler = (e) => {
        const handledKeys = ['ArrowDown', 'ArrowUp', 'Enter']
        setFocus(true)

        if (items.length <= 0 || handledKeys.indexOf(e.key) === -1) {
            return
        }

        if (e.key === 'Enter' && selectedAC !== null) {
            selectAutoComplete(selectedAC)
            e.stopPropagation()
            return
        }

        if (e.key === 'Enter' && selectedAC === null) {
            return
        }

        if (e.key === 'Enter' && items.length <= 0) {
            return
        }

        if (e.key === 'ArrowDown') {
            setSelectedAC((prev) => (
                prev + 1 < items.length ? prev + 1 : items.length - 1
            ))
        }

        if (e.key === 'ArrowUp') {
            setSelectedAC((prev) => (
                prev - 1 >= 0 ? prev - 1 : 0
            ))
        }

        if (selectedAC === null) {
            setSelectedAC(0)
            return
        }

        if (selectedAC === items.length - 1) {
            return
        }
    }

    const selectAutoComplete = (id: number) => {
        chooseValue(items[id])
        setSelectedAC(null)
        setFocus(false)
    }

    return (
        <div {...restProps}
             onKeyDown={keyDownAutocompleteHandler}
             onFocus={() => {
                 setFocus(true)
             }}
             onBlur={() => {
                 setFocus(false)
             }}
        >
            {children}
            {items.length > 0 && focus && (
                <div className={`${classDrawer ? classDrawer : ''} ${styles.container}`}>{
                    items.map(autocompleteRender)
                }</div>
            )}
        </div>
    );
};


export default Autocomplete;

export type TAutoCompleteItem = {
    image?: string,
    title: string,
    value: any,
}

type TAutocompelete = {
    items: TAutoCompleteItem[],
    search: string,
    chooseValue: Function,
    classDrawer?: string
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
