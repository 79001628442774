import React, {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    FormEventHandler,
    HTMLAttributes,
    useEffect,
    useRef,
    useState
} from 'react';
import styles from './SearchBar.module.scss';
import AxiosActions from "../../axios/axios";
import {useAppSelector} from "../../hooks/useAppSelector";
import Autocomplete, {TAutoCompleteItem} from "../Autocompelete/Autocomplete";
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import CustomButton from '../Button/CustomButton';


const SearchBar: FC<TSearchBar> = (
    {
        className,
        ...restProps
    }): JSX.Element => {

    const [searchString, setSearchString] = useState<string>('')
    const [categoryString, setCategoryString] = useState<string>('')
    const [cityString, setCityString] = useState<string>('')
    const [selectedCategoryId, setSelectedCategoryId] = useState<null | number>(null)
    const [autocomplete, setAutocomplete] = useState<TAutoCompleteItem[]>([])
    const [cities, setCities] = useState([])
    const {locale: lang} = useRouter()
    const rootCategories = useAppSelector(state => state.category.rootCategories)
    const router = useRouter()
    const refCategory = useRef<HTMLInputElement>(null)
    const {t} = useTranslation('common')

    const searchInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchString(() => (e.target.value.substring(0, 255)))
    }
    const categoryInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
    }
    const cityInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setCityString(() => (e.target.value.replace(/[а-я]+/gi, '')))
    }

    const submitHandler: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const url = new URL('/list/', window.location.href)
        if (selectedCategoryId) {
            url.pathname = '/list/' + rootCategories.find((category) => category.id === selectedCategoryId).url
        }

        if (searchString) {
            url.searchParams.append('search', searchString)
        }

        if (cityString) {
            url.searchParams.append('city', cityString)
        }

        router.push(url)
    }

    const searchCity = async () => {
        const service = new google.maps.places.AutocompleteService();
        const foundCities = await service.getPlacePredictions({
            input: cityString,
            componentRestrictions: {country: "SWE"},
            types: ['(cities)']
        });

        setCities(foundCities.predictions.map((prediction) => ({
            title: prediction.structured_formatting.main_text,
            value: prediction.structured_formatting.main_text
        })));
    }

    const selectCity = (item) => {
        setCityString(item.title)
    }

    useEffect(() => {
        if (cityString.length > 2) {
            searchCity()
        }
    }, [cityString])

    useEffect(() => {
        if (searchString && searchString.length > 1) {
            AxiosActions.searchAutocomplete(searchString)
                .then((res) => {
                    const items: TAutoCompleteItem[] = res.data.map((item) => {
                        return {
                            title: item,
                            value: item
                        }
                    })
                    setAutocomplete(items)
                })
        }
    }, [searchString])

    const noCategory = {
        title: t('No categories'),
        value: null,
    }
    const categories: TAutoCompleteItem[] = [noCategory , ...rootCategories.map(category => ({
        title: category.lang[lang],
        value: category.id,
        image: '/assets/img/' + category.image_src
    }))]

    return (
        <form {...restProps} className={`${className ? className : ''} ${styles.search}`}
             onSubmit={submitHandler}
        >
            <div className={styles.container}>
            <Autocomplete
                className={styles.search_container}
                items={autocomplete}
                search={searchString}
                classDrawer={styles.drawer}
                chooseValue={(item) => {
                    setSearchString(item.value)
                }}
            >
                <input
                    type="text"
                    name="search"
                    autoComplete="off"
                    placeholder={t('Search')}
                    onInput={searchInputHandler}
                    value={searchString}
                />
            </Autocomplete>
            <Autocomplete
                className={styles.categories_container}
                items={categories}
                search={categoryString}
                classDrawer={styles.drawer}
                chooseValue={(item) => {
                    setSelectedCategoryId(item.value)
                    setCategoryString(item.title)
                }}
            >
                <input
                    ref={refCategory}
                    type="text"
                    autoComplete="off"
                    placeholder={t('Category')}
                    value={categoryString}
                    onInput={categoryInputHandler}
                />
            </Autocomplete>
            <Autocomplete
                className={styles.city_container}
                classDrawer={styles.drawer}
                items={cities}
                search={cityString}
                chooseValue={selectCity}
            >
                <input
                    type="input"
                    name="city"
                    placeholder={t('City')}
                    onInput={cityInputHandler}
                    value={cityString}
                />
            </Autocomplete>
            </div>
            <div className={styles.button_container}>
                <CustomButton  title={t('Search')} clickAction={()=>{}}  type='submit' />
            </div>

        </form>
    );
};

export default SearchBar;

type TSearchBar = {} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;
