import {DetailedHTMLProps, FC, HTMLAttributes} from 'react';
import styles from './AdminSideMenu.module.scss';
import AdminSideMenuItem from "../AdminSideMenuItem/AdminSideMenuItem";
import {TAdminMenuItem} from "../../../layout/adminMenuItems";
import useTranslation from "next-translate/useTranslation";


const AdminSideMenu: FC<TAdminSideMenu> = (
    {
        items,
        depth = 0,
        className,
        ...restProps
    }): JSX.Element => {
    const {t} = useTranslation('common')

    const menuItems = items.map((menuItem, idx) => {
        const Component = menuItem.component || AdminSideMenuItem
        const children = menuItem.subItems?.length > 0 ? <AdminSideMenu items={menuItem.subItems} depth={depth + 1}/> : null
        const path = `/admin${menuItem.path}`
        return (
            <Component
                key={idx}
                title={t(menuItem.title)}
                path={path}
            >
                {children}
            </Component>
        )
    })

    return (
        <div {...restProps} className={`${styles.container} ${styles['depth-' + depth]} ${className}`}>
            {menuItems}
        </div>
    );
};


export default AdminSideMenu;


type TAdminSideMenu = {
    items: TAdminMenuItem[]
    depth?: number
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
