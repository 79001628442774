import {FilterActionTypes, FiltersActions, FiltersState} from "./types";

const initialState: FiltersState = {
    isFetching: true,
    filters: []
}

export default function filtersState(state: FiltersState = initialState, action: FiltersActions): FiltersState {
    switch (action.type) {
        case FilterActionTypes.SET_FILTERS_FETCHING:
            return {...state, isFetching: action.payload}
        case FilterActionTypes.SET_FILTERS:
            return {...state, filters: action.payload}
        default: return state
    }
}