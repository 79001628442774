import React, { useContext } from "react";
import s from "./PageFooter.module.scss";
import PageFooterLogo from "./elements/Logo/PageFooter-Logo";
import { LayoutContext } from "../../context/LayoutContext";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import useContactPopup from "../../hooks/useContactPopup";

const PageFooter = () => {
  const { showFooter } = useContext(LayoutContext);
  const { t } = useTranslation("common");

  const { show } = useContactPopup();

  const showContactPopup = () => {
    show();
  };

  return (
    <footer className={`${s.footer} ${!showFooter && s.footer_hidden}`}>
      <div className={`container ${s.wrapper}`}>
        <div className={s.logo}>
          <PageFooterLogo />
        </div>
        <div className={s.policy}>
        © Copyright {new Date().getFullYear()}
        </div>
        <div className={s.nav}>
          <div className={s.nav__title}>{t("Useful links")}</div>
          <nav className={s.nav__list}>
            <li className={s.nav__item}>
              <Link href={"/"}>{t("Home")}</Link>
            </li>
            <li className={s.nav__item}>
              <Link href={"/policy"}>{t("Privacy & Policy")}</Link>
            </li>
            <li className={s.nav__item}>
              <Link href={"/terms"}>{t("Terms & Conditions")}</Link>
            </li>
            <li className={s.nav__item}>
              <button
                className={s.contactBtn}
                type="button"
                onClick={showContactPopup}
              >
                {t("Contact")}
              </button>{" "}
            </li>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
