import {createAsyncThunk} from "@reduxjs/toolkit";
import AxiosActions from "../../../axios/axios";

import IAdminSection from "../../../models/IAdminSection";

export const getSectionsThunk = createAsyncThunk<Array<IAdminSection>, string>(
    "admin/getSections",
    async (token, {rejectWithValue}) => {
        try {
            const {data} = await AxiosActions.getAdminCategories(token)
            return data
        } catch (e) {
            rejectWithValue("error")
        }
    }
)