import {ICategory} from "../../models/ICategory";

export interface CategoryDataState {
    activeTab: ICategory
    activeCategory: ICategory
    categories: Array<ICategory>
}

export enum CategoryDataActionTypes {
    SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
    SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY",
    SET_CATEGORIES = "SET_CATEGORIES"
}

export interface SetActiveTabAction {
    type: CategoryDataActionTypes.SET_ACTIVE_TAB
    payload: ICategory
}

export interface SetActiveCategoryAction {
    type: CategoryDataActionTypes.SET_ACTIVE_CATEGORY
    payload: ICategory
}

export interface SetCategoriesAction {
    type: CategoryDataActionTypes.SET_CATEGORIES
    payload: Array<ICategory>
}

export type CategoryDataActions =
    SetActiveTabAction |
    SetActiveCategoryAction |
    SetCategoriesAction