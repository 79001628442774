import React from 'react';
import { FormSelectProps } from './FormSelectTypes';
import { useRouter } from 'next/router';
import s from './FormSelect.module.scss';
import { Icon } from '../Icon/Icon';
import useTranslation from 'next-translate/useTranslation';

const FormSelect = (props: FormSelectProps) => {
    const { locale: lang } = useRouter();

    const { t } = useTranslation('common');

    const placeholder = props.placeholder || t('Choose');

    const options = props.options.map((option, index) => {
        return (
            <div
                key={index}
                className={s.options__item}
                onClick={() => props.handleChange(option)}
            >
                {option.lang[lang]}
            </div>
        );
    });

    const findOption = () => {
        const option = props.options.find((opt) => opt.value == props.value);
        if (option) {
            return option.lang[lang];
        } else {
            return placeholder;
        }
    };

    return (
        <>
            {props.title ? (
                <div className={`${props.classes?.name || ''} ${s.title}`}>
                    {props.title[lang]}{' '}
                    {props.measure && '(' + props.measure[lang] + ')'}
                </div>
            ) : (
                ''
            )}

            <div
                className={`${props.classes?.container || ''} ${s.container}`}
                tabIndex={0}
                onBlur={props.handleClose}
            >
                <div
                    className={`${props.classes?.title || ''} ${s.select} `}
                    onClick={props.handleExpand}
                >
                    {props.icon?.prev ? (
                        <div className={s.prevIcon}>
                            <Icon
                                name={props.icon?.prev}
                                size={16}
                                color={'#276EAC'}
                                stroke={'#276EAC'}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                    <div
                        className={
                            props.value
                                ? `${s.value}`
                                : `${s.value} ${s.value_placeholder}`
                        }
                    >
                        {findOption()}
                    </div>

                    <div
                        className={`${s.toggle} ${
                            props.open ? s.toggle_open : ''
                        }`}
                    >
                        <Icon
                            name={'arrow_down_select'}
                            size={12}
                            stroke={'#595959'}
                        />
                    </div>
                </div>

                <div
                    className={
                        props.open
                            ? `${props.classes?.options || ''} ${s.options}`
                            : `${props.classes?.options || ''} ${s.options} ${
                                  s.options_hidden
                              }`
                    }
                >
                    {options.length ? (
                        <div className={`${s.options__wrapper}`}>
                            {!props.dontUseNone ? (
                                <div
                                    className={s.options__item}
                                    onClick={() =>
                                        props.handleChange({
                                            value: 0,
                                            lang: {
                                                en: '',
                                                ru: '',
                                                sv: '',
                                            },
                                        })
                                    }
                                >
                                    None
                                </div>
                            ) : (
                                ''
                            )}

                            {options}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    );
};

export default FormSelect;
