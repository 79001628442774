import {AppDispatch} from "../store";
import {AdminDispatch} from "../admin/store";
import UserActionCreator from "./actions";
import AxiosActions from "../../axios/axios";

const UserThunks = {
    getUser: (userId: string) => async (dispatch: AppDispatch|AdminDispatch) => {
        dispatch(UserActionCreator.setIsFetching(true))
        const user = await AxiosActions.getUser(userId)
        dispatch(UserActionCreator.setUserData(user.data))
        dispatch(UserActionCreator.setIsFetching(false))
    },
    getMe: (token) => async (dispatch: AppDispatch|AdminDispatch) => {
        dispatch(UserActionCreator.setIsFetching(true))
        try {
            const user = await AxiosActions.getMe(token)

            if (user.data.blocked){
                dispatch(UserActionCreator.setIsFetching(false))
                return
            }

            dispatch(UserActionCreator.setUserData(user.data))
            dispatch(UserActionCreator.setUserToken(token))
        } catch (e) {
            dispatch(UserActionCreator.setUserData(null))
            dispatch(UserActionCreator.setUserToken(null))
        }
        dispatch(UserActionCreator.setIsFetching(false))
    },
    getUserAdverts: (userId: any) => async (dispatch: AppDispatch) => {
        dispatch(UserActionCreator.setUserAdvertsFetching(true))
        const adverts = await AxiosActions.getUserAdverts(userId)
        dispatch(UserActionCreator.setUserAdverts(adverts.data.items))
        dispatch(UserActionCreator.setUserAdvertsFetching(false))
    },
    getUserFavouriteAdverts: (token: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(UserActionCreator.setUserAdvertsFetching(true))
            const {data} = await AxiosActions.getFavorites(token)
            dispatch(UserActionCreator.setUserFavouriteAdverts(data.items))
            dispatch(UserActionCreator.setUserAdvertsFetching(false))
        } catch (e) {
            console.log(e)
        }
    }
}

export default UserThunks