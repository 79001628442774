import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaginateQuery } from '../../models/PaginateQuery';
import { backendUrl } from '../../utils/url';
import { Paginator } from '../../models/Paginator';
import { SearchStatistics } from '../../models/SearchStatistics';
import { Sortable } from '../../models/Sortable';
import { SimpleAdvertsResponse } from '../../axios/response';
import { AdminState } from './store';
import { Searchable } from '../../models/Searchable';
import { UserListItem } from '../../models/UserListItem';
import {
    AdminCategory,
    AdminCategoryUploadData,
} from '../../models/AdminCategory';
import { Settings } from '../../models/Settings';
import { AdPlace } from '../../models/AdPlace';
import { AdvertsActions } from './adverts/slice';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrl(),
        prepareHeaders: (headers, api) => {
            const state = api.getState() as AdminState;
            const token = state?.user?.token;
            if (!token) {
                return headers;
            }
            headers.set('Authorization', 'Bearer ' + token);
            return headers;
        },
    }),
    tagTypes: [
        'Section',
        'User',
        'Setting',
        'AdPlaces',
        'UsersReviews',
        'Ad',
        'Ads',
    ],
    endpoints: (builder) => {
        return {
            getSearchStatistics: builder.query<
                Paginator<SearchStatistics>,
                PaginateQuery
            >({
                query: (query: PaginateQuery) => ({
                    url: 'search-statistics/get',
                    params: query,
                }),
            }),
            getAdverts: builder.query<
                SimpleAdvertsResponse,
                PaginateQuery & Sortable & { status: string }
            >({
                query: (query) => ({
                    url: 'adverts',
                    params: query,
                }),
            }),
            getUsers: builder.query<
                Paginator<UserListItem>,
                PaginateQuery & Sortable & Searchable
            >({
                query: (query) => ({
                    url: 'admin/users',
                    params: query,
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({
                                  type: 'User' as const,
                                  id,
                              })),
                              'User',
                          ]
                        : ['Section'],
            }),
            getUser: builder.query<UserListItem, { id: number | string }>({
                query: (query) => ({
                    url: `admin/users/${query.id}`,
                }),
                providesTags: (result, error, arg) =>
                    result ? [{ type: 'User', id: arg.id }] : ['User'],
            }),
            updateUser: builder.mutation<UserListItem, UserListItem>({
                query: (query) => ({
                    method: 'post',
                    url: `admin/users/save`,
                    body: query,
                }),
                invalidatesTags: (result, error, arg) =>
                    !!arg?.id ? [{ type: 'User', id: arg?.id }] : ['User'],
            }),
            deleteUser: builder.mutation<UserListItem, { id: number | string }>(
                {
                    query: (query) => ({
                        method: 'delete',
                        url: `/user/${query.id}`,
                    }),
                    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                        try {
                            await queryFulfilled;
                        } catch (e) {
                            console.log(e?.message || 'deleteUser failed');
                        }
                    },
                    invalidatesTags: (result, error, arg) =>
                        !!arg?.id ? [{ type: 'User', id: arg?.id }] : ['User'],
                }
            ),
            getSections: builder.query<
                AdminCategory[],
                { parentID?: number | string }
            >({
                query: (query) => ({
                    url: 'admin/sections',
                    params: query,
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({
                                  type: 'Section' as const,
                                  id,
                              })),
                              'Section',
                          ]
                        : ['Section'],
            }),
            getAllSections: builder.query<AdminCategory[], void>({
                query: (query) => ({
                    url: 'admin/sections/all',
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({
                                  type: 'Section' as const,
                                  id,
                              })),
                              'Section',
                          ]
                        : ['Section'],
            }),
            getSectionData: builder.query<
                { category: AdminCategory },
                { id: number }
            >({
                query: (query) => ({
                    url: `admin/sections/${query.id}`,
                }),
                providesTags: (result, error, arg) =>
                    result ? [{ type: 'Section', id: arg.id }] : ['Section'],
            }),
            saveSectionData: builder.mutation<
                { category: AdminCategoryUploadData },
                AdminCategoryUploadData
            >({
                query: (query) => {
                    const formData = new FormData();

                    for (let name in query) {
                        let value = query[name];
                        if (typeof query[name] === 'object') {
                            value = JSON.stringify(query[name]);
                        }

                        formData.set(name, value);
                    }

                    if  (typeof query['image'] !==  'undefined') {
                        formData.set('image', query['image'])
                    }

                    return {
                        method: 'post',
                        url: `admin/sections/save`,
                        body: formData,
                    };
                },
                invalidatesTags: (_result, _error, arg) => {
                    return !!arg?.id
                        ? [{ type: 'Section', id: arg?.id }]
                        : ['Section'];
                }
            }),
            deleteSection: builder.mutation<void, AdminCategory>({
                query: (query) => ({
                    method: 'delete',
                    url: `admin/sections/${query.id}`,
                }),
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    const patchResult = dispatch(
                        api.util.updateQueryData(
                            'getSections',
                            { parentID: !!arg.parent_id ? arg.parent_id : 0 },
                            (draft) => {
                                return draft.filter(
                                    (category) => category.id !== arg.id
                                );
                            }
                        )
                    );

                    try {
                        await queryFulfilled;
                    } catch (e) {
                        patchResult.undo();
                    }
                },
            }),
            getSettings: builder.query<Settings, void>({
                query: () => ({
                    url: `settings`,
                }),
                providesTags: ['Setting'],
            }),
            saveSettings: builder.mutation<void, Settings>({
                query: (query) => {
                    const formData = new FormData();

                    for (let name in query) {
                        formData.set(name, query[name]);
                    }

                    return {
                        method: 'post',
                        url: `settings/save`,
                        body: formData,
                    };
                },
            }),
            getAdPlaces: builder.query<AdPlace[], { type; entity_id }>({
                query: (query) => ({
                    url: `ad/places`,
                    params: query,
                }),
                providesTags: ['AdPlaces'],
            }),
            saveImage: builder.mutation({
                query: (query) => {
                    const formData = new FormData();
                    formData.append('image', query.file);
                    formData.append('previous', query.formData[query.type]);

                    return {
                        method: 'post',
                        url: `/ad/images/save`,
                        body: formData,
                    };
                },
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    const { formData, placeItem: placeData, type } = arg;
                    try {
                        const { data } = await queryFulfilled;

                        dispatch(
                            AdvertsActions.updateFormData({
                                placeData,
                                formData: { ...formData, [type]: data.image },
                            })
                        );
                    } catch (e) {
                        dispatch(
                            AdvertsActions.updateFormData({
                                placeData,
                                formData: { ...formData, [type]: '' },
                            })
                        );
                    }
                },
            }),
            getAdCompany: builder.query({
                query: (query) => ({
                    url: `ad/company/${query.id}`,
                    params: query,
                }),
            }),
            getAdCompanyStatistics: builder.query({
                query: (query) => ({
                    url: `ad/company/statistics/${query.company_id}`,
                    params: query,
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.ads.map(({ id }) => ({
                                  type: 'Ad' as const,
                                  id,
                              })),
                              'Ads',
                          ]
                        : ['Ads'],
            }),
            deleteAdCompanyItem: builder.mutation({
                query: (id) => ({
                    method: 'delete',
                    url: '/admin/ad',
                    body: { id },
                }),
                invalidatesTags: (result, error, id) => {
                    return !!id
                        ? [{ type: 'Ad', id }, 'AdPlaces']
                        : ['Ads', 'AdPlaces'];
                },
            }),
            saveAdCompany: builder.mutation({
                query: (query) => ({
                    method: 'post',
                    url: `ad/company/save`,
                    body: query,
                }),
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;
                        dispatch(AdvertsActions.setData(data));
                    } catch (e) {
                        console.log(e);
                    }
                },
            }),
            getCompanies: builder.query({
                query: (query) => ({
                    url: `ad/companies`,
                    params: query,
                }),
            }),
            getUsersReviews: builder.query({
                query: (query) => ({
                    url: `admin/reviews`,
                    params: query,
                }),
                providesTags: (result) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({
                                  type: 'UsersReviews' as const,
                                  id,
                              })),
                              'UsersReviews',
                          ]
                        : ['UsersReviews'],
            }),
            blockUserReview: builder.mutation({
                query: ({ id }) => ({
                    url: `admin/reviews/${id}/block`,
                    method: 'post',
                }),
                invalidatesTags: (result, error, arg) => {
                    return !!arg?.id
                        ? [{ type: 'UsersReviews', id: arg?.id }]
                        : ['UsersReviews'];
                },
            }),
        };
    },
});
