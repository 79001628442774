import type {EntityState, PayloadAction, Reducer} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {IChat} from "../../models/IChat";
import {IChatMessage} from "../../models/IChatMessage";
import {RootState} from "../store";
import {createChat, deleteCurrentChat, fetchChatMessages, fetchChats, sendMessage} from "./thunks";
import {chatAdapter, messageAdapter} from "./adapters";

export interface ChatsState {
    chats: {
        items: EntityState<IChat>,
        loading: boolean,
        maxPage: number,
    }
    selectedChatId: number | null,
    messages: {
        items: EntityState<IChatMessage>,
        loading: boolean,
    }
}

const initialState: ChatsState = {
    chats: {
        items: chatAdapter.getInitialState(),
        loading: false,
        maxPage: 0,
    },
    selectedChatId: null,
    messages: {
        items: messageAdapter.getInitialState(),
        loading: false,
    }
}

export const chatsSlice = createSlice({
    name: 'profileMessages',
    initialState,
    reducers: {
        updateChat(state, action) {
            chatAdapter.updateOne(state.chats.items, action)
        },
        addChat(state, action) {
            chatAdapter.addOne(state.chats.items, action)
        },
        updateMessage(state, action) {
            messageAdapter.updateOne(state.messages.items, action.payload)
        },
        setSelectedChatId(state: ChatsState, action: PayloadAction<number>) {
            if (action.payload === null) {
                state.selectedChatId = null
                return
            }

            state.selectedChatId = action.payload
        },
        setRead(state, action: PayloadAction<{ chatId: number, userId: number, firstMessageId: number }>) {
            setRead(state, action.payload)
        },
        clearMessages(state) {
            messageAdapter.removeAll(state.messages.items)
        },
        addMessage(state: ChatsState, action: PayloadAction<IChatMessage>) {
            const {selectedChatId, messages} = state

            const {payload: message} = action

            if (selectedChatId !== message?.chat_id) {
                return
            }

            messageAdapter.addOne(messages.items, message)
        }
    },
    extraReducers(builder) {
        //fetchMessages
        builder
        .addCase(fetchChatMessages.pending.type, (state: ChatsState) => {
            state.messages.loading = true
        })
        .addCase(fetchChatMessages.fulfilled.type, (state: ChatsState, action: PayloadAction<IChatMessage[]>) => {
            messageAdapter.addMany(state.messages.items, action.payload)
            state.messages.loading = false
        })
        .addCase(fetchChatMessages.rejected.type, (state: ChatsState) => {
            state.messages.loading = false
        })
        //sendMessage
        .addCase(sendMessage.pending.type, (state) => {
            state.messages.loading = true
        })
        .addCase(sendMessage.fulfilled.type, (state, action: PayloadAction<IChatMessage>) => {
            state.messages.loading = false
            messageAdapter.addOne(state.messages.items, action.payload)
        })
        .addCase(sendMessage.rejected.type, (state, action: PayloadAction<IChatMessage>) => {
            state.messages.loading = false
            messageAdapter.addOne(state.messages.items, action.payload)
        })
        //sendChats
        .addCase(fetchChats.pending.type, (state: ChatsState) => {
            state.chats.loading = true
        })
        .addCase(fetchChats.fulfilled.type, (state: ChatsState, action: PayloadAction<{ last_page: number, data: IChat[] }>) => {
            state.chats.loading = false
            state.chats.maxPage = action.payload.last_page ?? 0
            chatAdapter.addMany(state.chats.items, action.payload.data)
        })
        .addCase(fetchChats.rejected.type, (state: ChatsState, action:PayloadAction<IChat[]>) => {
            state.chats.loading = false
            chatAdapter.addMany(state.chats.items, action.payload)
        })
        //deleteChat
        .addCase(deleteCurrentChat.pending.type, (state: ChatsState) => {
            state.messages.loading = true
        })
        .addCase(deleteCurrentChat.fulfilled.type, (state: ChatsState) => {
            const selectedChatId = state.selectedChatId
            chatAdapter.removeOne(state.chats.items, selectedChatId)
            state.selectedChatId = null
            state.messages.loading = false
        })
        //createChat
        .addCase(createChat.fulfilled.type, (state: ChatsState, action: PayloadAction<IChat>) => {
            chatAdapter.addOne(state.chats.items, action.payload)
            state.selectedChatId = action.payload.id
        })
    },
})

const setRead = (state: ChatsState, {chatId, userId, firstMessageId}) => {
    if (state.selectedChatId !== chatId) {
        return
    }

    state.messages.items.ids.forEach((id) => {
        const message = {...state.messages.items.entities[id]}
        if (message.id <= firstMessageId && message.userId === userId) {
            messageAdapter.updateOne(
                state.messages.items,
                {id: message.id, changes: {read: true}}
            )
        }
    })
}

export const getSelectedChat = (state: RootState): IChat | null => state.profileMessages.chats.items.entities[state.profileMessages.selectedChatId] ?? null

export const ChatActions = chatsSlice.actions
export const reducer = chatsSlice.reducer as Reducer<ChatsState>
