import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import {rootReducer as reducer} from "./reducers";
import { thunk } from "redux-thunk";
import {publicApi} from "./api";

export const createStore = () => {
    return configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: {
                    ignoredActionPaths: ['payload.geometry.location', 'payload.geometry.viewport', "payload.geometry.bounds"],
                    ignoredPaths: ['editAdvert.location', "editAdvert.mapLocation", 'newAdvert.location', "newAdvert.mapLocation"],
                },
            })
                .concat(thunk).concat(publicApi.middleware)
        },
    })
}

export type RootStore = ReturnType<typeof createStore>
export type RootState = ReturnType<RootStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action
    >;
export type AppDispatch = RootStore['dispatch']

