import {CategoryActions, CategoryActionTypes, CategoryState, SetRootCategories} from "./types";
import {ICategory} from "../../models/ICategory";

const initialState: CategoryState = {
    isFetching: false,
    all: [],
    rootCategories: [],
    current: {} as ICategory,
    children: [],
    menu: [],
    tabs: [],
}

export default function categoryReducer(state: CategoryState = initialState, action: CategoryActions): CategoryState {
    switch (action.type) {
        case CategoryActionTypes.SET_CATEGORIES_FETCHING: return {...state, isFetching: action.payload}
        case CategoryActionTypes.SET_ALL_CATEGORIES: return {...state, all: action.payload}
        case CategoryActionTypes.SET_CURRENT_CATEGORY: return {...state, current: action.payload}
        case CategoryActionTypes.SET_TABS_CATEGORIES: return {...state, tabs: action.payload}
        case CategoryActionTypes.SET_ROOT_CATEGORIES: return {...state, rootCategories: action.payload}
        case CategoryActionTypes.SET_CHILD_CATEGORIES: return {...state, children: action.payload}
        case CategoryActionTypes.SET_MENU_CATEGORIES: return {...state, menu: action.payload}
        default: return state
    }
}