import {AdvertActions, AdvertActionTypes, AdvertState} from "./types";
import {IAdvertDetail} from "../../models/IAdvert";

const initialState: AdvertState = {
    data: {} as IAdvertDetail,
    similar: [],
    isFetching: false
}

export default function advertReducer(state: AdvertState = initialState, action: AdvertActions): AdvertState {
    switch (action.type) {
        case AdvertActionTypes.SET_ADVERT_FETCHING: return {...state, isFetching: action.payload}
        case AdvertActionTypes.SET_ADVERT_DATA: return {...state, data: action.payload}
        case AdvertActionTypes.SET_SIMILAR_ADVERTS: return {...state, similar: action.payload}
        default: return state
    }
}