import React from 'react';
import s from "./AdminHeader-Logo.module.scss";
import Image from "next/legacy/image";
import Link from "next/link";

const AdminHeaderLogo = () => {
    return (
        (<Link href={"/"} className={s.logo}>
            <>
                <Image src={"/assets/img/logo.png"} layout="fill" objectFit="contain" objectPosition="left"
                   alt="logo"/>
            </>
        </Link>)
    );
};

export default AdminHeaderLogo;
