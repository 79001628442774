import {ListActions, ListActionTypes, ListState} from "./types";

const initialState: ListState = {
    isFetching: true,
    isPremiumFetching: true,
    adverts: [],
    simpleAdverts: [],
    premium: [],
    page: 1,
    total: 0,
    sortIndex: 0,
    lastVisited: []
}

export default function listReducer(state: ListState = initialState, action: ListActions): ListState {
    switch (action.type) {
        case ListActionTypes.SET_LIST_FETCHING:
            return {...state, isFetching: action.payload}
        case ListActionTypes.SET_PREMIUM_FETCHING:
            return {...state, isPremiumFetching: action.payload}
        case ListActionTypes.SET_ADVERTS:
            return {...state, adverts: action.payload}
        case ListActionTypes.SET_PREMIUM:
            return {...state, premium: action.payload}
        case ListActionTypes.SET_PAGE:
            return {...state, page: action.payload}
        case ListActionTypes.SET_TOTAL:
            return {...state, total: action.payload}
        case ListActionTypes.SET_SORT_INDEX:
            return {...state, sortIndex: action.payload}
        case ListActionTypes.SET_SIMPLE_ADVERTS:
            return {...state, simpleAdverts: action.payload}
        case ListActionTypes.SET_LAST_VISITED:
            return {...state, lastVisited: action.payload}
        default:
            return state
    }
}
