import {useRouter} from "next/router";
import PublicLayout from "./PublicLayout";
import AdminLayout from "./AdminLayout";

const Layout = (props) => {
    const router = useRouter()

    if (router.asPath.startsWith('/admin')){
        return (
            <AdminLayout {...props}/>
        )
    }

    return (
        <PublicLayout {...props}/>
    )
}

export default Layout