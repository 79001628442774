import {DetailedHTMLProps, FC, HTMLAttributes, SyntheticEvent, useState} from 'react';
import styles from './ContactUsPopup.module.scss';
import Popup from "../Popup/Popup";
import useTranslation from "next-translate/useTranslation";
import useAlert from "../../hooks/useAlert";
import ContactUsForm from '../Forms/ContactUsForm/ContactUsForm';
import AxiosActions from '../../axios/axios';

const ContactUsPopup: FC<TContactUsPopup> = (
    {
        isShowed,
        hide,
        ...restProps
    }): JSX.Element => {
    const {t} = useTranslation('common')
    const [error, setError] = useState<string>("")
    const [isSending, setIsSending] = useState<boolean>(false);
    const {setAlert} = useAlert()
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        type: "",
        subject: "",
        comments: "",
    })
    const handleDataChange = (name: string, value: string) => {
        setUserData({...userData, [name]: value})
    }
    const validate = () => {
        let allow = true
        setError("")
        if (!userData.name || !userData.email || !userData.phone || !userData.phone || !userData.type || !userData.subject || !userData.comments) {
            setError(t("All fields are mandatory"))
            allow = false
        }

        return allow
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault()
        if(validate()){
            try {
                setIsSending(true)
                await AxiosActions.instance.post("/contact-us", {...userData})

                hide()
                setError('')
                setUserData({
                  name: "",
                  email: "",
                  phone: "",
                  type: "",
                  subject: "",
                  comments: "",
              })
                setAlert( t('Thank you for your message'), 'success')
            } catch (e) {
                console.log("error", e.response)
                setError(e.response?.data?.errors?.email[0]  ||  t('Something went wrong. Please try again later.'))
            } finally {
                setIsSending(false)
            }
        }
    }

    return (
      <Popup
        popup={
          <div {...restProps} className={styles.container}>
            <div
              className={styles.close}
              onClick={() => {
                hide();
              }}
            >
              ×
            </div>
            <ContactUsForm
              userData={userData}
              handleDataChange={handleDataChange}
              submit={submit}
              error={error}
              disableButton={isSending}
            />
          </div>
        }
        setVisible={(v) => hide()}
        visible={isShowed}
      />
    );
};


export default ContactUsPopup;


type TContactUsPopup = {
    isShowed: boolean
    hide: Function
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
