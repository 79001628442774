import Head from "next/head";
import React, {FC, useEffect} from "react";
import AdminHeader from "./AdminHeader/AdminHeader";
import AdminFooter from "./AdminFooter/AdminFooter";
import AdminContent from "../containers/Admin/AdminContent/AdminContent";
import {useRouter} from "next/router";
import withAdminPrivileges from "../hocs/withAdminPrivileges";
import {TLayout} from "./TLayout";

const AdminLayout: FC<TLayout> = (
    {
        children
    }) => {
    const router = useRouter()

    useEffect(() => {
        const handleRouteChange = (url) => {
            if (!url.startsWith('/admin')) {
                window.location.href = url
                throw "Abort route to public area."
            }
            return true
        }

        router.events.on('routeChangeStart', handleRouteChange)

        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [])
    return (
        <>
            <Head>
                <title>Administration Area</title>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="shortcut icon" href="/assets/favicon.ico"/>
            </Head>
            <AdminHeader/>
            <AdminContent>
                {children}
            </AdminContent>
            <AdminFooter/>
        </>
    );
}

export default withAdminPrivileges<TLayout>(AdminLayout)


