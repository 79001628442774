import {createContext} from "react";

export interface ILayoutContext {
    showFooter: boolean,
    setShowFooter: (show: boolean) => void
    showLoginPopup: boolean
    setShowLoginPopup: (show: boolean) => void
}

export const LayoutContext = createContext<ILayoutContext>({
    showFooter: true,
    setShowFooter: () => {},
    showLoginPopup: false,
    setShowLoginPopup: () => {}
})

