import React, {DetailedHTMLProps, FC, HTMLAttributes} from 'react';
import styles from './AdminContent.module.scss';
import AdminSideMenu from "../../../components/Admin/AdminSideMenu/AdminSideMenu";
import menuItems from "../../../layout/adminMenuItems";


const AdminContent: FC<TAdminContent> = (
    {
        children,
        ...restProps
    }): JSX.Element => {

    return (
        <main {...restProps} className={`${styles.container} admin_area`}>
            <AdminSideMenu items={menuItems}/>
            <div className={styles.content_wrapper}>{children}</div>
        </main>
    );
};


export default AdminContent;


type TAdminContent = {} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
