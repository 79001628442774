import {createAsyncThunk} from "@reduxjs/toolkit";
import AxiosActions from "../../axios/axios";
import {RootState} from "../store";
import {IChatMessage} from "../../models/IChatMessage";
import {IChat} from "../../models/IChat";

export const fetchChatMessages = createAsyncThunk<IChatMessage[], { token: string }, { state: RootState }>(
    'profileMessages/fetchChatMessages',
    async ({
               token
           }, thunkAPI) => {
        try {
            const state = thunkAPI.getState()
            const selectedChatId = state.profileMessages.selectedChatId
            const ids = state.profileMessages.messages.items.ids
            const firstMessageId = state.profileMessages.messages.items.ids[ids.length - 1] as number
            const {data} = await AxiosActions.getMessages(token, selectedChatId, firstMessageId)
            return data
        } catch (e) {
            console.log(e)

            return thunkAPI.rejectWithValue([])
        }
    }
)

export const fetchChats = createAsyncThunk<IChat[], { token: string, page: number }>(
    'profileMessages/fetchChats',
    async ({token, page}: { token: string, page: number }) => {
        const {data} = await AxiosActions.getChats(token, page)

        return data
    }
)

export const sendMessage = createAsyncThunk<IChatMessage, { token: string, message: string }, { state: RootState }>(
    'profileMessages/sendMessage',
    async ({token, message}: { token: string, message: string }, thunkAPI) => {
        const state = thunkAPI.getState()
        const {data} = await AxiosActions.sendMessage(token, state.profileMessages.selectedChatId, message)

        return data
    }
)

export const createChat = createAsyncThunk(
    'profileMessages/createChat',
    async ({token, advertId}: { token: string, advertId: number }) => {
        const {data} = await AxiosActions.createChat(token, advertId)

        return data;
    }
)

export const readMessages = createAsyncThunk<{ selectedChatId: number, lastMessageId: number, userId: number },
    { token: string, userId: number },
    { state: RootState }>(
    'profileMessages/readMessages',
    async ({
               token,
               userId
           }: { token: string, userId: number }, thunkAPI) => {
        const state = thunkAPI.getState()
        const selectedChatId = state.profileMessages.selectedChatId
        const ids = state.profileMessages.messages.items.ids
        const lastMessageId = state.profileMessages.messages.items.ids[ids.length - 1] as number
        await AxiosActions.readMessages(token, selectedChatId, lastMessageId)

        return {selectedChatId, lastMessageId, userId};
    }
)

export const deleteCurrentChat = createAsyncThunk<void, { token: string }, { state: RootState }>(
    'profileMessages/deleteCurrentChat',
    async ({token}: { token: string }, thunkAPI) => {
        const state = thunkAPI.getState()
        const selectedChatId = state.profileMessages.selectedChatId

        await AxiosActions.deleteChat(token, selectedChatId)
    }
)
