import {UserAction, UserActionTypes, UserState} from "./types";
import IUser from "../../models/IUser";

const initialState: UserState = {
    isFetching: true,
    token: null,
    data: {} as IUser,
    adverts: [],
    advertsFetching: true,
    favourites: []
}

export default function userReducer(state: UserState = initialState, action: UserAction): UserState {
    switch (action.type) {
        case UserActionTypes.HYDRATE: return {...state, ...action.payload.user}
        case UserActionTypes.SET_USER_FETCHING: return {...state, isFetching: action.payload}
        case UserActionTypes.SET_USER_DATA: return {...state, data: action.payload}
        case UserActionTypes.SET_USER_TOKEN: return {...state, token: action.payload}
        case UserActionTypes.SET_USER_ADVERTS: return {...state, adverts: action.payload}
        case UserActionTypes.SET_USER_ADVERTS_FETCHING: return {...state, advertsFetching: action.payload}
        case UserActionTypes.SET_USER_FAVOURITE_ADVERTS: return {...state, favourites: action.payload}
        default: return state
    }
}