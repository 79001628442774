module.exports = {
    locales: ["en", "sv"],
    defaultLocale: "en",
    keySeparator: ':',
    // fallbackLng: {
    //     default: ['sv'],
    //     'ru-RU': ['ru']
    // },
    pages: {
        "*": ["common"],
        "/": ["common", "home"]
    }
}
