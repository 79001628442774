type IconProps = {
    name: string
    color?: string
    size?: number
    stroke?: string,
    className?: string
}

export const Icon = ({name, color = "none", size = 16, stroke = "none", ...restProp}: IconProps) => {
    return (
            <svg width={size} viewBox="0 0 500 500" stroke={stroke} fill={color} {...restProp}>
                <use href={`/assets/svg/icons.svg#${name}`}/>
            </svg>

    )
}