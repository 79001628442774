import React, { useEffect, useRef } from 'react';
import s from './PageHeader-RootMenu.module.scss';
import { PageHeaderRootMenuProps } from '../../PageHeaderTypes';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useRouter } from 'next/router';
import Link from 'next/link';

const PageHeaderRootMenu = (props: PageHeaderRootMenuProps) => {
    const navRef = useRef<HTMLDivElement | null>(null);
    const { locale: lang } = useRouter();

    const current = useAppSelector((state) => state.category.current);
    const advert = useAppSelector((state) => state.advert.data);
    const root = current.url
        ? current.url.split('/')[0]
        : advert.category
        ? advert.category.url.split('/')[0]
        : '';

    const hideNav = () => {
        props.toggle();
    };

    const clickOutSide = (e: MouseEvent) => {
        if (navRef.current && !navRef.current.contains(e.target as Element)) {
            hideNav();
        }

        return true;
    };

    useEffect(() => {
        document.addEventListener('mousedown', { handleEvent: clickOutSide });

        return () => {
            document.removeEventListener('mousedown', {
                handleEvent: clickOutSide,
            });
        };
    }, []);

    const menuNodes = props.rootMenu?.map((category) => {
        return (
            <li
                key={category.id}
                className={
                    root === category.url
                        ? `${s.menu__item} ${s.menu__item_active}`
                        : `${s.menu__item}`
                }
            >
                <Link href={'/list/' + category.url}>
                    {category.lang[lang]}
                </Link>
            </li>
        );
    });

    return (
        <>
            {props.show ? (
                <div className={s.container}>
                    <nav className={`${s.menu} container`} ref={navRef}>
                        {menuNodes}
                    </nav>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default PageHeaderRootMenu;
