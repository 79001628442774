import React from 'react';
import s from './PageFooter-Logo.module.scss';
import Image from 'next/image';
import Link from 'next/link';

const PageFooterLogo = () => {
    return (
        <Link href={'/'} className={s.logo}>
            <>
                <Image
                    src={'/assets/img/logo_footer.png'}
                    fill
                    style={{ objectFit: 'contain', objectPosition: 'left' }}
                    alt="logo"
                />
            </>
        </Link>
    );
};

export default PageFooterLogo;
