import React, { useEffect, useState } from 'react';
import s from './PageHeader-Nav.module.scss';
import { PageHeaderNavProps } from '../../PageHeaderTypes';
import { Icon } from '../../../../components/Icon/Icon';
import CustomButton from '../../../../components/Button/CustomButton';
import { useAuth } from '../../../../context/AuthContext';
import PageHeaderProfile from '../Profile/PageHeader-Profile';
import PageHeaderRootMenu from '../RootMenu/PageHeader-RootMenu';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useUserEcho from '../../../../hooks/useUserEcho';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import UserActionCreator from '../../../../redux/user/actions';
import useTranslation from 'next-translate/useTranslation';

const PageHeaderNav = (props: PageHeaderNavProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const auth = useAuth();
    const router = useRouter();
    const user = useAppSelector((state) => state.user.data);
    const dispatch = useAppDispatch();
    const userChannel = useUserEcho();

    const { t } = useTranslation('common');

    const echoUserUpdateHandler = ({ user }) => {
        dispatch(UserActionCreator.setUserData(user));
    };

    const handleExpand = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        props.toggleRootMenu(false);
    };

    useEffect(() => {
        if (!userChannel) {
            return;
        }

        userChannel.listen('.user.update', echoUserUpdateHandler);

        return () => {
            userChannel.stopListening('.user.update', echoUserUpdateHandler);
        };
    }, [user, userChannel]);

    return (
        <>
            <div className={s.burger} onClick={handleExpand}>
                <Icon name={'burger'} size={22} color={'black'} />
            </div>
            <div className={show ? `${s.nav} ${s.nav_show}` : `${s.nav}`}>
                <button
                    className={s.nav__close}
                    onClick={handleClose}
                    type="button"
                >
                    <Icon name={'cross'} size={16} stroke={'black'} />
                </button>

                {!!auth?.user?.id && (
                    <Link className={s.profileMobile} href={'/profile'}>
                        <div className={s.profileMobile__info}>
                            <div className={s.profileMobile__name}>
                                {auth.user.firstName} {auth.user.lastName || ''}
                            </div>
                            <div className={s.profileMobile__email}>
                                {auth.user.email}
                            </div>
                        </div>
                        <div className={s.profileMobile__image}>
                            {auth.user.avatar ? (
                                <Image
                                    src={auth.user.avatar}
                                    style={{ objectFit: 'cover' }}
                                    fill
                                    alt={'user avatar'}
                                />
                            ) : (
                                <div className={s.profileMobile__placeholder}>
                                    {auth.user.firstName[0]}
                                    {auth.user.lastName &&
                                        auth.user.lastName[0]}
                                </div>
                            )}
                        </div>
                    </Link>
                )}

                <nav className={s.nav__list}>
                    <li className={`${s.nav__item} ${s.nav__item_category}`}>
                        <button
                            className={`${s.nav__button} ${
                                props.showRootMenu ? s.active : ''
                            }`}
                            onClick={() => props.toggleRootMenu()}
                            type="button"
                        >
                            <span className={s.nav__text}>{t('Category')}</span>
                        </button>
                    </li>

                    <li className={s.nav__menuMobile} onClick={handleClose}>
                        {props?.rootMenu?.length > 0 && (
                            <PageHeaderRootMenu
                                rootMenu={props.rootMenu}
                                show={props.showRootMenu}
                                toggle={() => {
                                    props.toggleRootMenu(false);
                                }}
                            />
                        )}
                    </li>

                    {!!auth?.user?.id ? (
                        <li className={s.nav__item}>
                            <Link
                                href={'/profile/favorites'}
                                onClick={handleClose}
                            >
                                <div className={s.nav__text}>
                                    {t('Favorites')}
                                </div>
                            </Link>
                        </li>
                    ) : (
                        <li className={s.nav__item}>
                            <button
                                className={s.nav__button}
                                onClick={props.toggleLoginPopup}
                            >
                                {t('Favorites')}
                            </button>
                        </li>
                    )}

                    {!!auth?.user?.id ? (
                        <li className={`${s.nav__item} ${s.nav__item_profile}`}>
                            <button
                                className={`${s.nav__button}  ${
                                    showProfile ? s.active : ''
                                }`}
                                onClick={() => setShowProfile(!showProfile)}
                                type="button"
                            >
                                <div className={s.nav__text}>
                                    {t('Profile')}
                                </div>
                                {user?.unread_messages > 0 && (
                                    <div className={s.unread_messages} />
                                )}
                            </button>
                        </li>
                    ) : (
                        <li className={s.nav__item}>
                            <button
                                className={`${s.nav__button}`}
                                onClick={props.toggleLoginPopup}
                                type="button"
                            >
                                <div className={s.nav__text}>{t('Login')}</div>
                            </button>
                        </li>
                    )}

                    {!!auth?.user?.id ? (
                        <li className={`${s.nav__item} ${s.nav__item_signOut}`}>
                            <button
                                className={`${s.nav__button}`}
                                onClick={() => {
                                    handleClose();
                                    auth.logout();
                                }}
                                type="button"
                            >
                                <span className={s.nav__text}>
                                    {t('Sign out')}
                                </span>
                            </button>
                        </li>
                    ) : (
                        ''
                    )}

                    <li className={`${s.nav__item} ${s.nav__item_post}`}>
                        <CustomButton
                            title={t('Post Listing')}
                            clickAction={() => {
                                !auth?.user?.id
                                    ? props.toggleLoginPopup()
                                    : router.push('/new-advert');
                            }}
                        />
                    </li>
                </nav>
                {showProfile && (
                    <div className={s.profile}>
                        <PageHeaderProfile setShow={setShowProfile} />
                    </div>
                )}
            </div>
        </>
    );
};

export default PageHeaderNav;
