import {CategoryDataActions, CategoryDataActionTypes, CategoryDataState} from "./types";
import {ICategory} from "../../models/ICategory";

const initialState: CategoryDataState = {
    activeCategory: {} as ICategory,
    activeTab: {} as ICategory,
    categories: []
}

export default function categoryDataState(state: CategoryDataState = initialState, action: CategoryDataActions): CategoryDataState {
    switch (action.type) {
        case CategoryDataActionTypes.SET_ACTIVE_CATEGORY:
            return {...state, activeCategory: action.payload}
        case CategoryDataActionTypes.SET_ACTIVE_TAB:
            return {...state, activeTab: action.payload}
        case CategoryDataActionTypes.SET_CATEGORIES:
            return {...state, categories: action.payload}
        default: return state
    }
}