// AuthContext.js
import {createContext, FC, useState} from 'react';

const ALERT_TIME = 5000;
const initialState: {
    text: string,
    type: "success" | "info" | "warning" | "error" | undefined
} = {
    text: '',
    type: undefined,
};

const AlertContext = createContext({
    ...initialState,
    setAlert: (text: string, type:  "success" | "info" | "warning" | "error" | undefined) => {}
});

export const AlertProvider: FC<any> = ({ children }) => {
    const [text, setText] = useState('');
    const [type, setType] = useState<"success" | "info" | "warning" | "error" | undefined>(undefined);

    const setAlert = (text: string, type:  "success" | "info" | "warning" | "error" | undefined) => {
        setText(text);
        setType(type);

        setTimeout(() => {
            setText('');
            setType(undefined);
        }, ALERT_TIME);
    };

    return (
        <AlertContext.Provider
            value={{
                text,
                type,
                setAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;

