import React, {DetailedHTMLProps, FC, HTMLAttributes, SyntheticEvent, useState} from 'react';
import SignupForm from "./SignupForm";
import {useAuth} from "../../../context/AuthContext";
import useAlert from "../../../hooks/useAlert";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from 'next/router';

const SignupFormContainer: FC<TSignupFormContainer> = (
    {
        visible,
        setVisible,
        isPopup,
        switchPopup,
        ...restProps
    }): JSX.Element => {

    const [registerData, setRegisterData] = useState({
        accountType: "individual",
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: ""
    },)

    const [errors, setErrors] = useState<any>({})

    const {locale: lang} = useRouter()

    const {register} = useAuth()
    const {setAlert} = useAlert()

    const handleDataChange = (name: string, value: string) => {
        setRegisterData({...registerData, [name]: value})
    }

    const {t} = useTranslation('common')

    const validate = () => {
        let allow = true
        if (!registerData.firstName && registerData.accountType === "individual") {
            setErrors((prev: any) => ({...prev, firstName: `${t("First name")} ${t("is required")}`}))
            allow = false
        }
        if (!registerData.firstName && registerData.accountType === "company") {
            setErrors((prev: any) => ({...prev, firstName: `${t("Company name")} ${t("is required")}`}))
            allow = false
        }
        if (!registerData.lastName && registerData.accountType === "individual") {
            setErrors((prev: any) => ({...prev, lastName: `${t("Last name")} ${t("is required")}`}))
            allow = false
        }
        if (!registerData.email) {
            setErrors((prev: any) => ({...prev, email: `${t("Email")} ${t("is required")}`}))
            allow = false
        }
        if (!registerData.password) {
            setErrors((prev: any) => ({...prev, password: `${t("Password")} ${t("is required")}`}))
            allow = false
        }
        if (!registerData.confirmPassword) {
            setErrors((prev: any) => ({...prev, confirmPassword: `${t("Confirm password")} ${t("is required")}`}))
            allow = false
        }
        if (registerData.password !== registerData.confirmPassword) {
            setErrors((prev: any) => ({...prev, confirmPassword: t("Passwords does not match")}))
            allow = false
        }

        return allow
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault()
        let allow = validate()
        if (allow) {
            const response = await register({...registerData, lang})
            if (response && response.errors) {
                setErrors(response.errors)
                setAlert(t("Something went wrong. Please try again later."), "error")
            } else {
                setVisible(false)
                switchPopup("login")
                setAlert(t("You are registered on ODIN24. Follow the link (sent to your email) to confirm your account details."), "success")
                setRegisterData({
                    accountType: "individual",
                    email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: ""
                })
            }
        }

    }

    return (
        <SignupForm
            visible={visible} setVisible={setVisible} isPopup={isPopup}
            switchPopup={switchPopup} registerData={registerData} handleDataChange={handleDataChange}
            submit={submit} errors={errors} setRegisterData={setRegisterData} {...restProps}
        />
    );
};

type TSignupFormContainer = {
    visible: boolean,
    setVisible: (value: boolean) => void
    isPopup: boolean
    switchPopup: (popupName: string) => void
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;


export default SignupFormContainer