import React, {useRef} from 'react';
import {PopupTypes} from "./PopupTypes";
import s from "./Popup.module.scss";

const Popup = (props: PopupTypes) => {
    const backdropRef = useRef<HTMLDivElement | null>(null)
    const backDropClose = (e: any) => {
        if (backdropRef.current && backdropRef.current == e.target) {
            props.setVisible(false)
        }
    }
    return (
        <div className={props.visible ? s.overlay : s.overlayHidden} onClick={backDropClose} ref={backdropRef}>
            {props.popup}
        </div>
    );
};
export default Popup;