import React, {CSSProperties, DetailedHTMLProps, FC, HTMLAttributes, SyntheticEvent, useEffect, useState} from 'react';
import s from "./SignupForm.module.scss"
import FormInput from "../../FormInput/FormInput";
import Image from "next/legacy/image"
import FormSelectContainer from "../../FormSelect/FormSelectContiainer";
import {IRegisterData} from "../../../models/IRegisterData";
import CustomButton from "../../Button/CustomButton";
import useTranslation from "next-translate/useTranslation";

const SignupForm: FC<TSignupForm> = (
    {
        visible,
        setVisible,
        isPopup,
        switchPopup,
        registerData,
        setRegisterData,
        handleDataChange,
        submit,
        errors,
        ...restProps
    }): JSX.Element => {

    const {t} = useTranslation('common')

    const buttonStyles: CSSProperties = {
        paddingLeft: 81,
        paddingRight: 81,
        width: 'auto',
        margin: 'auto',
        marginTop: 39,
    };

    const [accountType, setAccountType] = useState<string>(registerData.accountType)

    useEffect(() => {
        setAccountType(registerData.accountType)
        let data = {} as IRegisterData
        data.accountType = registerData.accountType
        setRegisterData(registerData)
    }, [registerData.accountType])


    const [inputPasswordType, setInputPasswordType] = useState<"text" | "password">("password")
    const [passwordIcon, setPasswordIcon] = useState("password_hidden")
    const [inputRePasswordType, setInputRePasswordType] = useState<"text" | "password">("password")
    const [rePasswordIcon, setRePasswordIcon] = useState("password_hidden")

    const changePasswordInput = () => {
        inputPasswordType === "text" ? setInputPasswordType("password") : setInputPasswordType("text")
        passwordIcon === "password_hidden" ? setPasswordIcon("password_show") : setPasswordIcon("password_hidden")
    }
    const changeRePasswordInput = () => {
        inputRePasswordType === "text" ? setInputRePasswordType("password") : setInputRePasswordType("text")
        rePasswordIcon === "password_hidden" ? setRePasswordIcon("password_show") : setRePasswordIcon("password_hidden")
    }

    const createErrorString = (errors: any) => {
        if (errors) {
            if (Array.isArray(errors)) {
                return errors.map((error, index) => {
                    return <div key={index} className={s.error}>{error}</div>
                })
            }

            return <div className={s.error}>{errors}</div>
        }
    }

    return (
        <div {...restProps} className={s.popup}>
            {isPopup ? <div className={s.close} onClick={() => setVisible(false)}>
                <Image src={"/assets/img/auth/cross.png"} objectFit={"cover"} layout={"fill"} alt={"close"}/>
            </div> : ''}
            <form onSubmit={submit} className={s.form}>
                <div className={s.title}>
                    <span>{t("Signup")}</span>
                </div>
                <div className={s.block}>
                    <span className={s.text}>{t("Account type")}</span>
                    <div className={s.select}>
                        <FormSelectContainer
                            dontUseNone={true}
                            classes={{
                                container: `${s.selectContainer}`,
                                title: errors.accountType
                                    ? `${s.selectTitle} ${s.selectTitle_error}`
                                    : `${s.selectTitle}`,
                            }}
                            name={'accountType'}
                            options={[
                                {
                                    lang: {
                                        ru: "Физическое лицо",
                                        sv: "Privat",
                                        en: "Individual",
                                    },
                                    value: "individual"
                                },
                                {
                                    lang: {
                                        ru: "Компания",
                                        sv: "Företag",
                                        en: "Company",
                                    },
                                    value: "company"
                                }
                            ]}
                            onChange={(name, value) => handleDataChange(name.toString(), value.toString())}
                            initialValue={registerData.accountType}
                        />
                        <div className={s.errorContainer}>
                            {createErrorString(errors.accountType)}
                        </div>
                    </div>
                </div>
                <div className={errors.email ? `${s.block} ${s.withError}` : `${s.block}`}>
                    <span className={s.text}>{t("Email")}</span>
                    <div className={`${s.input}`}>

                        <FormInput name={"email"}
                                   value={registerData.email}
                                   onChange={(name, value) => {
                                       handleDataChange(name.toString(), value.toString())
                                   }}
                                   placeholder={" "}
                                   icon={{prev: 'email'}}/>
                        <div className={s.errorContainer}>
                            {createErrorString(errors.email)}
                        </div>
                    </div>
                </div>


                {
                    accountType === "individual" ?
                        <>
                            <div className={errors.firstName ? `${s.block} ${s.withError}` : `${s.block}`}>
                                <span className={s.text}>{t("Name")}</span>
                                <div className={s.input}>

                                    <FormInput name={"firstName"}
                                               value={registerData.firstName}
                                               onChange={(name, value) => {
                                                   handleDataChange(name.toString(), value.toString())
                                               }}
                                               placeholder={" "}/>
                                    <div className={s.errorContainer}>
                                        {createErrorString(errors.firstName)}
                                    </div>

                                </div>
                            </div>
                            <div className={errors.lastName ? `${s.block} ${s.withError}` : `${s.block}`}>
                                <span className={s.text}>{t("Surname")}</span>
                                <div className={s.input}>

                                    <FormInput name={"lastName"}
                                               value={registerData.lastName}
                                               onChange={(name, value) => {
                                                   handleDataChange(name.toString(), value.toString())
                                               }}
                                               placeholder={" "}/>
                                    <div className={s.errorContainer}>
                                        {createErrorString(errors.lastName)}
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={errors.firstName ? `${s.block} ${s.withError}` : `${s.block}`}>
                                <span className={s.text}>{t("Company name")}</span>
                                <div className={s.input}>

                                    <FormInput name={"firstName"}
                                               value={registerData.firstName}
                                               onChange={(name, value) => {
                                                   handleDataChange(name.toString(), value.toString())
                                               }}
                                               placeholder={" "}/>
                                    <div className={s.errorContainer}>
                                        {createErrorString(errors.firstName)}
                                    </div>

                                </div>
                            </div>
                            <div className={errors.address ? `${s.block} ${s.withError}` : `${s.block}`}>
                                <span className={s.text}>{t("Address")}</span>
                                <div className={s.input}>

                                    <FormInput name={"address"}
                                               value={registerData.address}
                                               onChange={(name, value) => {
                                                   handleDataChange(name.toString(), value.toString())
                                               }}
                                               placeholder={" "}/>
                                    <div className={s.errorContainer}>
                                        {createErrorString(errors.address)}
                                    </div>

                                </div>
                            </div>
                        </>
                }


                <div className={errors.password ? `${s.block} ${s.withError}` : `${s.block}`}>
                    <span className={s.text}>{t("Password")}</span>
                    <div className={s.input}>

                        <FormInput type={inputPasswordType} name={"password"}
                                   value={registerData.password}
                                   onChange={(name, value) => {
                                       handleDataChange(name.toString(), value.toString())
                                   }}
                                   placeholder={" "}
                                   icon={{prev: "password", post: passwordIcon, onClick: changePasswordInput}}
                        />
                        <div className={s.errorContainer}>
                            {createErrorString(errors.password)}
                        </div>
                    </div>
                </div>

                <div className={errors.confirmPassword ? `${s.block} ${s.withError}` : `${s.block}`}>

                    <span className={s.text}>{t("Re-Password")}</span>
                    <div className={s.input}>

                        <FormInput type={inputRePasswordType} name={"confirmPassword"}
                                   value={registerData.confirmPassword}
                                   onChange={(name, value) => {
                                       handleDataChange(name.toString(), value.toString())
                                   }} placeholder={" "}
                                   icon={{prev: "password", post: rePasswordIcon, onClick: changeRePasswordInput}}
                        />

                        <div className={s.errorContainer}>
                            {createErrorString(errors.confirmPassword)}
                        </div>

                    </div>

                </div>

                <div className={s.message}>
                    <div>
                        <span className={s.helpText}
                              onClick={() => switchPopup("login")}>{t("Already have an account?")}</span>
                    </div>
                </div>
                <CustomButton  title={t("Signup")} clickAction={()=>{}}
                              styles={buttonStyles} type="submit"/>

            </form>
        </div>
    );
};

type TSignupForm = {
    visible: boolean,
    setVisible: (value: boolean) => void
    isPopup: boolean
    switchPopup: (popupName: string) => void
    registerData: IRegisterData
    setRegisterData: any
    handleDataChange: (name: string, value: string) => void
    submit: (e: SyntheticEvent) => void
    errors: any
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default SignupForm;
