import IUser from "../../models/IUser";
import {IAdvertShort, IAdvertUser} from "../../models/IAdvert";
import {RootState} from "../store";
import {AdminState} from "../admin/store";

export interface UserState {
    isFetching: boolean
    data: IUser
    token?: string
    adverts: Array<IAdvertUser>,
    advertsFetching: boolean,
    favourites: Array<IAdvertShort>
}

export enum UserActionTypes {
    SET_USER_FETCHING = "SET_USER_FETCHING",
    SET_USER_DATA = "SET_USER_DATA",
    SET_USER_TOKEN = "SET_USER_TOKEN",
    SET_USER_ADVERTS = "SET_USER_ADVERTS",
    SET_USER_ADVERTS_FETCHING = "SET_USER_ADVERTS_FETCHING",
    SET_USER_FAVOURITE_ADVERTS = "SET_USER_FAVOURITE_ADVERTS",
    HYDRATE = "__NEXT_REDUX_WRAPPER_HYDRATE__"
}

export interface SetUserFetchingAction {
    type: UserActionTypes.SET_USER_FETCHING
    payload: boolean
}

export interface SetUserDataAction {
    type: UserActionTypes.SET_USER_DATA
    payload: IUser
}


export interface SetUserAdvertsAction {
    type: UserActionTypes.SET_USER_ADVERTS
    payload: Array<IAdvertUser>
}

export interface SetUserAdvertsFetchingAction {
    type: UserActionTypes.SET_USER_ADVERTS_FETCHING
    payload: boolean
}

export interface SetUserTokenAction {
    type: UserActionTypes.SET_USER_TOKEN
    payload: string
}

export interface HydrateAction {
    type: UserActionTypes.HYDRATE
    payload: RootState|AdminState
}

export interface SetUserFavouriteAdvertsAction {
    type: UserActionTypes.SET_USER_FAVOURITE_ADVERTS
    payload: Array<IAdvertShort>
}

export type UserAction =
    SetUserFetchingAction |
    SetUserDataAction |
    SetUserAdvertsAction |
    SetUserAdvertsFetchingAction |
    SetUserTokenAction |
    SetUserFavouriteAdvertsAction |
    HydrateAction