import useEcho from "./useEcho";
import {useAuth} from "../context/AuthContext";
import {useEffect, useState} from "react";
import {Channel} from "laravel-echo";

export default () => {
    const echo = useEcho()
    const {user} = useAuth()

    const [channel, setChannel] = useState<Channel|undefined>(undefined)

    useEffect(() => {
        if (user) {
            const newChannel = echo?.private('user.' + user.id)
            setChannel(newChannel)
            return () => {
                echo?.leaveChannel('user.' + user.id)
            }
        }
    }, [user, echo])


    return channel
}
