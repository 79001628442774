import {
    CategoryActionTypes,
    SetAllCategories,
    SetCategoriesFetching,
    SetChildCategories,
    SetCurrentCategory, SetMenuCategories, SetRootCategories,
    SetTabsCategories
} from "./types";
import {ICategory, ICategoryRoot} from "../../models/ICategory";

const CategoryActionCreator = {
    setIsFetching: (fetcing: boolean): SetCategoriesFetching => (
        {type: CategoryActionTypes.SET_CATEGORIES_FETCHING, payload: fetcing}
    ),
    setAllCategories: (categories: Array<ICategory>): SetAllCategories => (
        {type: CategoryActionTypes.SET_ALL_CATEGORIES, payload: categories}
    ),
    setCurrentCategory: (category: ICategory): SetCurrentCategory => (
        {type: CategoryActionTypes.SET_CURRENT_CATEGORY, payload: category}
    ),
    setTabsCategories: (categories: Array<ICategory>): SetTabsCategories => (
        {type: CategoryActionTypes.SET_TABS_CATEGORIES, payload: categories}
    ),
    setChildCategories: (categories: Array<ICategory>): SetChildCategories => (
        {type: CategoryActionTypes.SET_CHILD_CATEGORIES, payload: categories}
    ),
    setMenuCategories: (categories: Array<ICategory>): SetMenuCategories => (
        {type: CategoryActionTypes.SET_MENU_CATEGORIES, payload: categories}
    ),
    setRootCategories: (categories: Array<ICategoryRoot>): SetRootCategories => (
        {type: CategoryActionTypes.SET_ROOT_CATEGORIES, payload: categories}
    )
}

export default CategoryActionCreator