import {useContext} from "react";
import Echo from "laravel-echo";
import {EchoContext} from "../context/EchoContext";

const useEcho = (): Echo | null => {

    return useContext(EchoContext);
};


export default useEcho;
