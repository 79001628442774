import {FC, FocusEvent} from 'react';
import {useRouter} from "next/router";
import s from "./FormInput.module.scss"
import {Icon} from "../Icon/Icon";
import {ILang} from "../../models/ILang";
import PhoneInput from "react-phone-number-input";
import {E164Number} from "libphonenumber-js";

const FormInput: FC<TFormInputProps> = (
    {
        type,
        name,
        onChange,
        onBlur,
        title,
        initialValue,
        value,
        placeholder,
        disabled,
        measure,
        classes,
        icon,
        ...restProps
    }): JSX.Element => {
    const {locale: lang} = useRouter()
    // @ts-ignore
    const blur = (e) => onBlur(e)

    return (
        <div {...restProps}>
            {title ? <div
                className={`${classes?.title || ""} ${s.title}`}>{title[lang]} {measure && "(" + measure[lang] + ")"}</div> : ""}
            <div className={`${s.container}`}>
                <label className={disabled
                    ? `${classes?.container || ""} ${s.inputWrapper} ${s.disabled}`
                    : `${classes?.container || ""} ${s.inputWrapper}`}>
                    {
                        icon?.prev ? <span className={`${s.icon} ${s.icon_prev}`}>
                    <Icon name={icon?.prev} size={16} color={"#276EAC"} stroke={"#276EAC"}/>
                </span> : ""
                    }

                    {
                        type !== "phone" ?
                            <input className={`${classes?.input || ""} ${s.input}`} value={value || ""}
                                   type={type || "text"}
                                   onChange={(e) => onChange(name, e.target.value)}
                                   {...(onBlur ? {onBlur: blur} : {})}
                                   placeholder={placeholder || "Type"}
                                   disabled={disabled}
                            /> :

                            <PhoneInput onChange={(value) => {onChange(name, value)}} defaultCountry={"SE"}
                                value={value as E164Number}/>
                    }


                    {
                        icon?.post ? <span className={`${s.icon} ${s.icon_post}`}>
                            {icon?.onClick ? <div onClick={icon?.onClick}>
                                    <Icon name={icon?.post} size={16} color={"#276EAC"} stroke={"#276EAC"}/>
                                </div> :
                                <Icon name={icon?.post} size={16} color={"#276EAC"} stroke={"#276EAC"}/>}
                </span> : ""
                    }

                </label>
            </div>
        </div>

    )

};

type TFormInputProps = {
    type?: "text" | "number" | "password" | "phone"
    name: string | number
    onChange: (name: string | number, value: string | number) => void
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void
    title?: ILang
    initialValue?: string | number
    value?: string | number
    placeholder?: string
    disabled?: boolean
    measure?: ILang
    classes?: {
        container?: string
        title?: string
        input?: string
    }
    icon?: {
        prev?: string
        post?: string
        onClick?: () => void
    }
}
export default FormInput;
