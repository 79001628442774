import {ICategory, ICategoryRoot} from "../../models/ICategory";
import {IFilter} from "../../models/IFilter";
import {ITariff, ITariffType} from "../../models/ITariff";

export interface NewAdvertState {
    isFetching: boolean
    data: any
    rootCategories: Array<ICategoryRoot>
    activeRootCategory: ICategoryRoot
    categories: Array<ICategory>
    activeCategory: ICategory
    fields: Array<IFilter>
    location: any
    mapLocation: any
    mode: "main_premium" | "catalog_premium" | "free"
    tariffTypes: Array<ITariffType>
    selectedTariff: ITariff
    user: {
        advertDisplayName: string
        advertEmail: string
        advertPhone?: string
    }
}

export enum NewAdvertTypes {
    SET_NEW_ADVERT_FETCHING = "SET_NEW_ADVERT_FETCHING",
    SET_NEW_ADVERT_DATA = "SET_NEW_ADVERT_DATA",
    SET_NEW_ADVERT_ROOT_CATEGORIES = "SET_NEW_ADVERT_ROOT_CATEGORIES",
    SET_NEW_ADVERT_ACTIVE_ROOT_CATEGORY = "SET_NEW_ADVERT_ACTIVE_ROOT_CATEGORY",
    SET_NEW_ADVERT_CATEGORIES = "SET_NEW_ADVERT_CATEGORIES",
    SET_NEW_ADVERT_ACTIVE_CATEGORY = "SET_NEW_ADVERT_ACTIVE_CATEGORY",
    SET_NEW_ADVERT_FIELDS = "SET_NEW_ADVERT_FIELDS",
    SET_NEW_ADVERT_LOCATION = "SET_NEW_ADVERT_LOCATION",
    SET_NEW_ADVERT_MAP_LOCATION = "SET_NEW_ADVERT_MAP_LOCATION",
    SET_NEW_ADVERT_MODE = "SET_NEW_ADVERT_MODE",
    SET_NEW_ADVERT_TARIFF_TYPES = "SET_NEW_ADVERT_TARIFF_TYPES",
    SET_NEW_ADVERT_TARIFF = "SET_NEW_ADVERT_TARIFF",
    SET_NEW_ADVERT_USER = "SET_NEW_ADVERT_USER",
    CLEAR_NEW_ADVERT_STATE = "CLEAR_NEW_ADVERT_STATE"
}

export interface SetNewAdvertFetchingAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_FETCHING
    payload: boolean
}

export interface SetNewAdvertDataAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_DATA
    payload: any
}

export interface SetNewAdvertRootCategoriesAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_ROOT_CATEGORIES
    payload: Array<ICategoryRoot>
}

export interface SetNewAdvertActiveRootCategoryAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_ACTIVE_ROOT_CATEGORY
    payload: ICategoryRoot
}

export interface SetNewAdvertCategoriesAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_CATEGORIES
    payload: Array<ICategory>
}

export interface SetNewAdvertActiveCategoryAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_ACTIVE_CATEGORY
    payload: ICategory
}

export interface SetNewAdvertFieldsAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_FIELDS
    payload: Array<IFilter>
}

export interface SetNewAdvertLocationAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_LOCATION
    payload: any
}

export interface SetNewAdvertMapLocationAction {
    type: NewAdvertTypes.SET_NEW_ADVERT_MAP_LOCATION
    payload: any
}

export interface SetNewAdvertMode {
    type: NewAdvertTypes.SET_NEW_ADVERT_MODE
    payload: "main_premium" | "catalog_premium" | "free"
}

export interface SetNewAdvertTariffTypes {
    type: NewAdvertTypes.SET_NEW_ADVERT_TARIFF_TYPES
    payload: Array<ITariffType>
}

export interface SetNewAdvertTariff {
    type: NewAdvertTypes.SET_NEW_ADVERT_TARIFF
    payload: ITariff
}

export interface SetNewAdvertUser {
    type: NewAdvertTypes.SET_NEW_ADVERT_USER
    payload: {
        advertDisplayName: string
        advertEmail: string
        advertPhone?: string
    }
}

export interface ClearNewAdvertState {
    type: NewAdvertTypes.CLEAR_NEW_ADVERT_STATE
}

export type NewAdvertActions =
    SetNewAdvertFetchingAction |
    SetNewAdvertDataAction |
    SetNewAdvertRootCategoriesAction |
    SetNewAdvertActiveRootCategoryAction |
    SetNewAdvertCategoriesAction |
    SetNewAdvertActiveCategoryAction |
    SetNewAdvertFieldsAction |
    SetNewAdvertLocationAction |
    SetNewAdvertMapLocationAction |
    SetNewAdvertMode |
    SetNewAdvertTariffTypes |
    SetNewAdvertTariff |
    SetNewAdvertUser |
    ClearNewAdvertState