import getConfig from "next/config";

export const GoogleTagManager = () => {
  const { publicRuntimeConfig } = getConfig();
  const scriptInjectorHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "${publicRuntimeConfig.GOOGLE_TAG}");`;
  
  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GOOGLE_TAG}`}
        crossOrigin="anonymous"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          ${scriptInjectorHTML}
    `,
        }}
      />
    </>
  );
};
