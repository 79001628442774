import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {backendUrl} from "../utils/url";
import {RootState} from "./store";
import { IAdvertShort } from "../models/IAdvert";
import IUserReview from "../models/IUserReview";

export const publicApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrl(),
        prepareHeaders: (headers, api) => {
            const state = api.getState() as RootState
            const token = state?.user?.token
            if (!token) {
                return headers
            }
            headers.set('Authorization', 'Bearer ' + token)
            return headers
        },
    }),
    tagTypes: ['UserReviews', 'User'],
    endpoints: (builder) => {
        return {
            getAdPlace: builder.query({
                query: (query) => ({
                    url: '/ad',
                    params: query,
                }),
            }),
            incrementClick: builder.mutation({
                query: (query) => ({
                    url: '/ad/click',
                    body: query,
                    method: 'post',
                }),
            }),
            getUser: builder.query({
                query: ({ id }) => ({
                    url: `/user/${id}`,
                }),
                providesTags: (result, error, arg) =>
                    result ? [{ type: 'User', id: arg.id }] : ['User'],
            }),
            getUserAdverts: builder.query<
                { items: IAdvertShort[]; total: number },
                { id: number }
            >({
                query: ({ id }) => ({
                    url: `/user/${id}/adverts`,
                }),
                providesTags: (result, error, arg) =>
                    result ? [{ type: 'User', id: arg.id }] : ['User'],
            }),
            getUserReviews: builder.query<{count: number, items: IUserReview[]},{id:number}>({
                query: ({ id }) => ({
                    url: `/user/${id}/reviews`,
                }),
                providesTags: (result, error, arg) =>
                    result
                        ? [{ type: 'UserReviews', id: arg.id }]
                        : ['UserReviews'],
            }),
            createUserReview: builder.mutation<IUserReview,  {userId: number, score: number, reviewText: string}>({
                query: ({ userId, score, reviewText }) => ({
                    url: `/user/${userId}/reviews/create`,
                    body: {
                        score,
                        reviewText,
                    },
                    method: 'post',
                }),
                invalidatesTags: (result, error, arg) =>
                    !!arg?.userId
                        ? [
                              { type: 'UserReviews', id: arg?.userId },
                          ]
                        : ['UserReviews'],
            }),
            getActualAds: builder.query<{ items: IAdvertShort[]; total: number }, {limit: number}>({
                query: ({limit}) => ({
                    url: `/adverts`,
                    params: {
                        sort_field: 'id',
                        sort_order: 'desc',
                        limit: limit
                    },
                }),
            }),
            getSubCategories: builder.query({
                query: ({category}) => ({
                    url: `category/${category}`
                }),
            }),
        };
    },
})
