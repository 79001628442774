import React, {useState} from 'react';
import {Option} from "../../models/IFilter";
import {FormSelectContainerProps} from "./FormSelectTypes";
import FormSelect from "./FormSelect";

const FormSelectContainer = (props: FormSelectContainerProps) => {

    const [open, setOpen] = useState<boolean>(false)
    const [value, setValue] = useState<string | number>(props.initialValue || "")

    const handleExpand = () => {
        setOpen(!open)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (option: Option) => {
        handleClose()
        setValue(option.value)
        props.onChange(props.name, option.value)
    }

    const formSelectProps = {
        ...props, handleChange, handleClose,
        handleExpand, open, value: props.initialValue || ""
    }

    return (
        <FormSelect {...formSelectProps}/>
    );
};

export default FormSelectContainer;