import {createSlice, PayloadAction, Reducer} from "@reduxjs/toolkit";
import {AdminState} from "../store";

interface AdvertsState {
    selectedPlaces: Array<any>,
    companyInfo: any
}

const initialState: AdvertsState = {
    companyInfo: {
        name: '',
        email: '',
    },
    selectedPlaces: [],
}

const defaultFormData = {
    name: '',
    desktop_image: '',
    mobile_image: '',
    start_from: '',
    end_to: '',
    link: '',
    price: ''
}

const advertsSlice = createSlice(
    {
        name: "admin/adverts",
        initialState,
        reducers: {
            add: (state, action: PayloadAction<{place: any, index: number, page: any}>) => {
                const {place, index, page} = action.payload
                const places = state.selectedPlaces.filter((item) => item.index === index && item.place.id === place.id )
                if (places.length > 0){
                    return
                }

                const newName = `${
                  (page.type_parent_name ? page.type_parent_name + " / " : "")
                }${page.type_name} / ${place.place_name}`;


                const newPlace = {index, place, page, formData: {...defaultFormData, name: newName}}
                state.selectedPlaces.push(newPlace)
            },
            remove: (state, action: PayloadAction<{place: any, index: number}>) => {
                const {place, index} = action.payload
                const indexOf = state.selectedPlaces.findIndex((item) => item.index === index && item.place.id === place.id )
                if (indexOf === -1){
                    return
                }

                state.selectedPlaces.splice(indexOf, 1)
            },
            updateFormData: (state, action) => {
                const {placeData, formData} = action.payload
                const place = state.selectedPlaces.find((item) => item.place?.id === placeData.place.id && item.index === placeData.index)
                !!place  && Object.assign(place.formData, formData)
            },
            updateCompanyInfo: (state, action) => {
                Object.assign(state.companyInfo, action.payload)
            },
            clearData: () => initialState,
            setData: (state, action) => ({...state, ...action.payload})
        },
    }
)

export const isSelectedPlace = (place, index) => {
    return (state: AdminState) => {
        return state.adverts.selectedPlaces.findIndex((item) => item.index === index && item.place.id === place.id) !== -1
    }
}
export const getSelectedPlace = (place, index) => {
    return (state: AdminState) => {
        const i = state.adverts.selectedPlaces.findIndex((item) => item.index === index && item.place.id === place.id)
        if (i === -1) {return null}

        return  state.adverts.selectedPlaces[i]
    }
}

export const AdvertsActions = advertsSlice.actions
export default advertsSlice.reducer as Reducer<AdvertsState>
