import React from 'react'
import s from "./AdminHeader.module.scss"
import AdminHeaderLogo from "./elements/Logo/AdminHeader-Logo"
import AdminButton, {ButtonStyle, ButtonType} from "../../components/Admin/AdminButton/AdminButton";
import {useAdminSelector} from "../../hooks/useAdminSelector";
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import {useAuth} from "../../context/AuthContext";

const AdminHeader = () => {
    const router = useRouter()
    const user = useAdminSelector((state) => state.user.data)
    const {logout} = useAuth()
    const {t} = useTranslation('common')

    return (
        <header className={`${s.header}`}>
            <div className={s.header__top}>
                <div className={`${s.topWrapper} container-full`}>
                    <div className={s.logo}>
                        <AdminHeaderLogo/>
                    </div>
                    <div className={s.center}>
                        <div className={s.nav}>
                            <AdminButton
                                onClick={() => {router.push('/')}}
                                title={t('Go to site').toUpperCase()}
                            />
                        </div>
                        <div className={s.user}>
                            ADMIN: {user?.firstName} {user?.lastName}
                        </div>
                    </div>
                    <div className={s.exit}>
                        <AdminButton
                            className={s.exit_button}
                            title={t('Exit').toUpperCase()}
                            type={ButtonType.DANGEROUS}
                            style={ButtonStyle.OUTLINE}
                            onClick={() => {logout()}}
                        />
                    </div>

                </div>
            </div>
        </header>
    );
};

export default AdminHeader;
