import React from 'react';
import s from "./AdminFooter.module.scss"
import AdminFooterLogo from "./elements/Logo/AdminFooter-Logo";

const AdminFooter = () => {

    return (
        <footer className={`${s.footer}`}>
            <div className={`container-full ${s.wrapper}`}>
                <div className={s.logo}>
                    <AdminFooterLogo/>
                </div>
                <div className={s.policy}>
                    {/* It is a long established fact that a reader will be distracted by the readable content of a page */}
                </div>
                <div className={s.nav}>
                    {/* <div className={s.nav__title}>Useful links</div>
                    <nav className={s.nav__list}>
                        <li className={s.nav__item}>Home</li>
                        <li className={s.nav__item}>Privacy  & Policy</li>
                        <li className={s.nav__item}>Terms & Conditions</li>
                        <li className={s.nav__item}>Contact </li>
                    </nav> */}
                </div>
            </div>

        </footer>
    );
};

export default AdminFooter;