import getConfig from "next/config";

const {publicRuntimeConfig} = getConfig()

const removeDoubleSlash = (path: string) => (
    path.replace(/([^:])[/]{2,}/igm, '$1/')
);

const pathTrim = (path: string) => (
    path
        .replace(/[/]+/igm, '/')
        .replace(/^\/+/igm, '')
        .replace(/[/]$/igm, '')
        .trim()
);

const backendUrl = (...path: string[]) => (
    removeDoubleSlash(`${publicRuntimeConfig.API_URL}/${pathTrim(path.join('/'))}`)
);

export {
    backendUrl,
};
