import {createStore as createStoreRoot, RootStore} from "./store";
import {AdminStore, createStore as createStoreAdmin} from "./admin/store";
import {createWrapper} from "next-redux-wrapper";

let path = null;

export const storeCreator =  (preloadedState) => {
    if (!path && preloadedState.router){
        path = preloadedState.router.asPath
    }

    if (typeof window !== 'undefined'){
        path = window.location.pathname
    }

    if (path?.startsWith('/admin')){
        return createStoreAdmin()
    }

    return createStoreRoot()
}

export const wrapper = createWrapper<RootStore|AdminStore>(storeCreator);