import React, {CSSProperties, DetailedHTMLProps, FC, HTMLAttributes, SyntheticEvent, useState} from 'react';
import s from "./ForgotPasswordForm.module.scss"
import Image from "next/legacy/image";
import FormInput from "../../FormInput/FormInput";
import CustomButton from "../../Button/CustomButton";
import useAlert from "../../../hooks/useAlert";
import AxiosActions from "../../../axios/axios";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from 'next/router';

const ForgotPasswordForm: FC<TForgotPasswordForm> = (
    {
        isPopup,
        setVisible,
        switchPopup,
        ...restProps
    }): JSX.Element => {

    const {t} = useTranslation('common')

    const {locale: lang} = useRouter()

    const [fetching, setFetching] = useState(false)
    const [email, setEmail] = useState<string>("")
    const {setAlert} = useAlert()

    const buttonStyles: CSSProperties = {
        paddingLeft: 81,
        paddingRight: 81,
        width: 'auto',
        margin: 'auto',
        marginTop: 39,
    };

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault()

        if(email){
            setFetching(true)
            try {
                await AxiosActions.instance.post("/forgot-password", {email, lang})
                setAlert(t("A link to reset your password has been sent to your email"), "info")
            } catch (e) {
                setAlert(t("Something went wrong. Please try again later."), "error")
            } finally {
                setFetching(false)
                setVisible(false)
            }
        }
    }

    return (
        <div {...restProps} className={s.popup}>
            {isPopup ? <div className={s.close} onClick={() => setVisible(false)}>
                <Image src={"/assets/img/auth/cross.png"} objectFit={"cover"} layout={"fill"} alt={"close"}/>
            </div> : ''}
            <form className={s.form} onSubmit={submit}>
                <div className={s.title}>
                    <span>{t("Forgot password")}</span>
                </div>
                <div className={s.block}>
                    <span className={s.text}>{t("Email")}</span>
                    <div className={s.input}>
                        <FormInput name={"email"} value={email} onChange={(name, value) => {
                            setEmail(() => value.toString())
                        }} placeholder={" "} icon={{prev: 'email'}}/>
                    </div>
                </div>
                <div className={s.message}>
                    <div>
                        <span className={s.helpText}
                              onClick={() => switchPopup("login")}>{t("Sign in?")}</span>
                    </div>
                </div>

                <CustomButton  title={t("Submit")} clickAction={()=>{}} styles={buttonStyles}
                              disabled={fetching} type='submit'/>

            </form>
        </div>
    );
};

type TForgotPasswordForm = {
    isPopup: boolean
    setVisible: (visible: boolean) => void
    switchPopup: (popup: string) => void
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default ForgotPasswordForm;
