import ICoordinate from "../../models/ICoordinate";
import {IAdvertShort} from "../../models/IAdvert";

export interface MapState {
    isFetchingCoordinates: boolean
    isPopupShow: boolean
    coordinates: Array<ICoordinate>
    isFetchingPopupAdvert: boolean
    isExpand: boolean
    popupAdvert: IAdvertShort
    zoom: number
    center: google.maps.LatLngLiteral
    map: google.maps.Map | null
    formattedAddress: string
}

export enum MapActionTypes {
    SET_FETCHING_COORDINATES = "SET_FETCHING_COORDINATES",
    SET_FETCHING_POPUP_ADVERT = "SET_FETCHING_POPUP_ADVERT",
    SET_COORDINATES = "SET_COORDINATES",
    SET_POPUP_ADVERT = "SET_POPUP_ADVERT",
    SET_POPUP_SHOW = "SET_POPUP_SHOW",
    SET_EXPAND = "SET_EXPAND",
    SET_ZOOM = "SET_ZOOM",
    SET_CENTER = "SET_CENTER",
    SET_MAP = "SET_MAP",
    SET_FORMATTED_ADDRESS = "SET_FORMATTED_ADDRESS"
}

export interface SetFetchingCoordinatesAction {
    type: MapActionTypes.SET_FETCHING_COORDINATES
    payload: boolean
}

export interface SetFetchingPopupAdvertAction {
    type: MapActionTypes.SET_FETCHING_POPUP_ADVERT
    payload: boolean
}

export interface SetCoordinatesAction {
    type: MapActionTypes.SET_COORDINATES
    payload: Array<ICoordinate>
}

export interface SetPopupAdvertAction {
    type: MapActionTypes.SET_POPUP_ADVERT
    payload: IAdvertShort
}

export interface SetPopupShowAction {
    type: MapActionTypes.SET_POPUP_SHOW
    payload: boolean
}

export interface SetExpandAction {
    type: MapActionTypes.SET_EXPAND
    payload: boolean
}

export interface SetZoomAction {
    type: MapActionTypes.SET_ZOOM
    payload: number
}

export interface SetCenterAction {
    type: MapActionTypes.SET_CENTER
    payload: google.maps.LatLngLiteral
}

export interface SetMapAction {
    type: MapActionTypes.SET_MAP
    payload: google.maps.Map
}
export interface SetFormattedAddressAction {
    type: MapActionTypes.SET_FORMATTED_ADDRESS
    payload: string
}


export type MapAction =
    SetFetchingCoordinatesAction |
    SetFetchingPopupAdvertAction |
    SetCoordinatesAction |
    SetPopupAdvertAction |
    SetPopupShowAction |
    SetExpandAction |
    SetZoomAction |
    SetCenterAction |
    SetMapAction |
    SetFormattedAddressAction