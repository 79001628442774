import {createContext, useContext} from "react";
import AxiosActions from "../axios/axios";
import IUser from "../models/IUser";
import {IRegisterLangData} from "../models/IRegisterData";
import {useCookies} from "react-cookie";
import UserActionCreator from "../redux/user/actions";
import {useDispatch, useSelector} from "react-redux";
import {AdminState, AdminStore} from "../redux/admin/store";
import {RootState, RootStore} from "../redux/store";

export interface IAuthData {
    user: IUser | null
    token: string | null
}

export interface IAuthContext {
    user: IUser | null
    token: string | null
    login: (authData: { email: string, password: string }) => any | { message: string }
    register: (registerData: IRegisterLangData) => any | { message: string }
    logout: () => void
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const token = useSelector((state: AdminState | RootState) => state.user.token)
    const user = useSelector((state: AdminState | RootState) => state.user.data)
    const dispatch = useDispatch<AdminStore['dispatch'] | RootStore['dispatch']>()

    const login = async (authData: { email: string, password: string }) => {
        try {
            const response = await AxiosActions.login(authData)
            dispatch(UserActionCreator.setUserData(response.data.user))
            dispatch(UserActionCreator.setUserToken(response.data.token))
            setCookie("token", response.data.token, {path: '/'})
        } catch (e) {
            dispatch(UserActionCreator.setUserData(null))
            dispatch(UserActionCreator.setUserToken(null))
            removeCookie("token")
            return {message: "Invalid login or password"}
        }
    }

    const register = async (registerData: IRegisterLangData): Promise<any> => {
        try {
            await AxiosActions.register(registerData)
            return
        } catch (e: any) {
            return e.response.data
        }
    }

    const logout = async () => {
        if (token) {
            try {
                await AxiosActions.logout(token)
            } catch (e) {
                console.log(e)
            }
        }
        dispatch(UserActionCreator.setUserData(null))
        dispatch(UserActionCreator.setUserToken(null))
        removeCookie("token")
        location.reload()
    }

    return (
        <AuthContext.Provider value={{user, logout, token, login, register}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)