import React, {createContext, DetailedHTMLProps, FC, HTMLAttributes, useEffect, useState} from 'react';
import isBrowser from "../utils/isBrowser";
import {useAuth} from "./AuthContext";
import {backendUrl} from "../utils/url";
import Echo from "laravel-echo";
import getConfig from "next/config";

export const EchoContext = createContext<any>(null);

const EchoProvider: FC<TEchoProvider> = (
    {
        ...restProps
    }) => {
    const [initialValue, setInitialValue] = useState<null|Echo>(null);
    const {token} = useAuth();
    const { publicRuntimeConfig } = getConfig()

    useEffect((): any => {
        if (!isBrowser() || !token) {
            return
        }

        (async () => {
            (window as any)?.__ws?.disconnect?.();
            (window as any).__ws = null;

            if (!(window as any).Pusher) {
                (window as any).Pusher = (await import('pusher-js')).default;
            }
            const LaravelEcho = (await import('laravel-echo')).default;

            const hasForceTLS = String(publicRuntimeConfig.WS.FORCE_TLS) === 'true';

            const laravelEcho = new LaravelEcho({
                broadcaster: `pusher`,
                withoutInterceptors: true,
                key: publicRuntimeConfig.WS.KEY,
                cluster: publicRuntimeConfig.WS.CLUSTER,
                authEndpoint: backendUrl(publicRuntimeConfig.WS.AUTH_ENDPOINT || ''),
                wsHost: publicRuntimeConfig.WS.HOST,
                wsPort: publicRuntimeConfig.WS.PORT,
                wssPort: publicRuntimeConfig.WS.PORT,
                forceTLS: hasForceTLS,
                disableStats: String(publicRuntimeConfig.WS.DISABLE_STATS) === 'true',
                enabledTransports: [`ws${hasForceTLS ? `s` : ``}`],
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            });

            setInitialValue(laravelEcho);
            (window as any).__ws = laravelEcho;
        })();
    }, [token, setInitialValue]);


    return (
        <EchoContext.Provider value={initialValue}>
            {restProps.children}
        </EchoContext.Provider>
    );
};

type TEchoProvider = {} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default EchoProvider;