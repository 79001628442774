import React, { useContext, useEffect, useState } from 'react';
import s from './PageHeader.module.scss';
import PageHeaderLogo from './elements/Logo/PageHeader-Logo';
import PageHeaderNav from './elements/Nav/PageHeader-Nav';
import PageHeaderRootMenu from './elements/RootMenu/PageHeader-RootMenu';
import LangToggle from '../../components/LangToggle/LangToggle';
import { Icon } from '../../components/Icon/Icon';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import SignupFormContainer from '../../components/Forms/SignupForm/SignupFormContainer';
import Popup from '../../components/Popup/Popup';
import { useAuth } from '../../context/AuthContext';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import { LayoutContext } from '../../context/LayoutContext';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import CategoryThunks from '../../redux/category/thunks';
import useAdaptive from '../../hooks/useAdaptive';

const PageHeader = () => {
    const rootMenu = useAppSelector((state) => state?.category?.rootCategories);
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { isDesktop, isBigTablet } = useAdaptive();
    const router = useRouter();

    const { showLoginPopup, setShowLoginPopup } = useContext(LayoutContext);
    const [showRootMenu, setShowRootMenu] = useState<boolean>(false);
    const [popup, setPopup] = useState<string>('login');

    const loginPopup = (
        <LoginForm
            visible={showLoginPopup}
            setVisible={setShowLoginPopup}
            isPopup={true}
            switchPopup={setPopup}
        />
    );

    const signUpPopup = (
        <SignupFormContainer
            visible={showLoginPopup}
            setVisible={setShowLoginPopup}
            isPopup={true}
            switchPopup={setPopup}
        />
    );

    const forgotPasswordPopup = (
        <ForgotPasswordForm
            isPopup={true}
            setVisible={setShowLoginPopup}
            switchPopup={setPopup}
        />
    );

    const toggleRootMenu = () => {
        setShowRootMenu((prev) => !prev);
    };

    useEffect(() => {
        dispatch(CategoryThunks.getRootCategories());
    }, []);

    useEffect(() => {
        if (user) {
            setShowLoginPopup(false);
        }
    }, [user]);

    useEffect(() => {
        if (!showLoginPopup) {
            setPopup('login');
        }
    }, [showLoginPopup]);

    return (
        <header className={`${s.header}`}>
            <div className={s.header__top}>
                <div className={`${s.topWrapper} container`}>
                    <PageHeaderLogo />
                    <div
                        className={s.addButton}
                        onClick={() => {
                            !user
                                ? setShowLoginPopup(true)
                                : router.push('/new-advert');
                        }}
                    >
                        <Icon name={'add_circle'} size={24} />
                    </div>
                    <div className={s.langToggleMobile}>
                        <LangToggle hideFlag={true} />
                    </div>
                    <PageHeaderNav
                        showRootMenu={showRootMenu}
                        toggleRootMenu={toggleRootMenu}
                        toggleLoginPopup={() => setShowLoginPopup(true)}
                        rootMenu={rootMenu}
                    />
                    <div className={s.langToggle}>
                        <LangToggle />
                    </div>
                </div>
            </div>
            {rootMenu?.length > 0 && (isDesktop || isBigTablet) && (
                <PageHeaderRootMenu
                    rootMenu={rootMenu}
                    show={showRootMenu}
                    toggle={() => setShowRootMenu(!showRootMenu)}
                />
            )}

            <Popup
                popup={
                    popup === 'login'
                        ? loginPopup
                        : popup === 'signUp'
                        ? signUpPopup
                        : forgotPasswordPopup
                }
                setVisible={setShowLoginPopup}
                visible={showLoginPopup}
            />
        </header>
    );
};

export default PageHeader;
