import {IFilter} from "../../models/IFilter";

export interface FiltersState {
    isFetching: boolean
    filters: Array<IFilter>
}

export enum FilterActionTypes {
    SET_FILTERS_FETCHING= "SET_FILTERS_FETCHING",
    SET_FILTERS = "SET_FILTERS"
}

export interface SetFiltersFetchingAction {
    type: FilterActionTypes.SET_FILTERS_FETCHING
    payload: boolean
}

export interface SetFiltersAction {
    type: FilterActionTypes.SET_FILTERS
    payload: Array<IFilter>
}


export type FiltersActions =
    SetFiltersFetchingAction |
    SetFiltersAction