import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import {adminReducer as reducer} from "./reducers";
import {api} from "./api";

export const createStore = () => {
    return configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                serializableCheck: false
            }).concat(thunk).concat(api.middleware)
        },
    })
}

export type AdminStore = ReturnType<typeof createStore>
export type AdminState = ReturnType<AdminStore['getState']>
export type AdminThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AdminState,
    unknown,
    Action
    >;
export type AdminDispatch = AdminStore['dispatch']

