import {ICategory, ICategoryRoot} from "../../models/ICategory";

export interface CategoryState {
    isFetching: boolean
    all: Array<ICategory>
    current: ICategory
    rootCategories: ICategoryRoot[]
    children: Array<ICategory>
    tabs: Array<ICategory>
    menu: Array<ICategory>
}

export enum CategoryActionTypes {
    SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES",
    SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY",
    SET_TABS_CATEGORIES = "SET_TABS_CATEGORIES",
    SET_CHILD_CATEGORIES = "SET_CHILD_CATEGORIES",
    SET_MENU_CATEGORIES = "SET_MENU_CATEGORIES",
    SET_CATEGORIES_FETCHING = "SET_CATEGORIES_FETCHING",
    SET_ROOT_CATEGORIES = "SET_ROOT_CATEGORIES"
}

export interface SetCurrentCategory {
    type: CategoryActionTypes.SET_CURRENT_CATEGORY
    payload: ICategory
}

export interface SetAllCategories {
    type: CategoryActionTypes.SET_ALL_CATEGORIES
    payload: Array<ICategory>
}

export interface SetTabsCategories {
    type: CategoryActionTypes.SET_TABS_CATEGORIES
    payload: Array<ICategory>
}

export interface SetRootCategories {
    type: CategoryActionTypes.SET_ROOT_CATEGORIES
    payload: Array<ICategoryRoot>
}

export interface SetChildCategories {
    type: CategoryActionTypes.SET_CHILD_CATEGORIES
    payload: Array<ICategory>
}

export interface SetMenuCategories {
    type: CategoryActionTypes.SET_MENU_CATEGORIES
    payload: Array<ICategory>
}

export interface SetCategoriesFetching {
    type: CategoryActionTypes.SET_CATEGORIES_FETCHING
    payload: boolean
}

export type CategoryActions =
    SetCurrentCategory |
    SetCategoriesFetching |
    SetChildCategories |
    SetTabsCategories |
    SetAllCategories |
    SetMenuCategories |
    SetRootCategories