import {
    SetUserAdvertsAction,
    SetUserAdvertsFetchingAction,
    SetUserDataAction,
    SetUserFavouriteAdvertsAction,
    SetUserFetchingAction,
    SetUserTokenAction,
    UserActionTypes
} from "./types";
import IUser from "../../models/IUser";
import {IAdvertShort, IAdvertUser} from "../../models/IAdvert";

const UserActionCreator = {
    setIsFetching: (fetching: boolean): SetUserFetchingAction => (
        {type: UserActionTypes.SET_USER_FETCHING, payload: fetching}
    ),
    setUserData: (data: IUser): SetUserDataAction => (
        {type: UserActionTypes.SET_USER_DATA, payload: data}
    ),
    setUserToken: (token: string): SetUserTokenAction => (
        {type: UserActionTypes.SET_USER_TOKEN, payload: token}
    ),
    setUserAdverts: (data: Array<IAdvertUser>): SetUserAdvertsAction => (
        {type: UserActionTypes.SET_USER_ADVERTS, payload: data}
    ),
    setUserAdvertsFetching: (fetching: boolean): SetUserAdvertsFetchingAction => (
        {type: UserActionTypes.SET_USER_ADVERTS_FETCHING, payload: fetching}
    ),
    setUserFavouriteAdverts: (adverts: Array<IAdvertShort>): SetUserFavouriteAdvertsAction => (
        {type: UserActionTypes.SET_USER_FAVOURITE_ADVERTS, payload: adverts}
    )
}

export default UserActionCreator