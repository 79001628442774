import {FiltersDataActions, FiltersDataActionTypes, FiltersDataState, IFiltersData} from "./types";

const initialState: FiltersDataState = {
    data: {} as IFiltersData
}

export default function filtersDataReducer(state: FiltersDataState = initialState, action: FiltersDataActions): FiltersDataState {
    switch (action.type) {
        case FiltersDataActionTypes.SET_FILTER_DATA: return {...state, data: action.payload}
        default: return state
    }
}