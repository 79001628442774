import {IAdvertShort} from "../../models/IAdvert";

export interface ListState {
    isFetching: boolean
    isPremiumFetching: boolean
    adverts: Array<IAdvertShort>
    premium: Array<IAdvertShort>
    simpleAdverts: Array<IAdvertShort>
    lastVisited: Array<IAdvertShort>
    total: number
    page: number
    sortIndex: number
}

export enum ListActionTypes {
    SET_LIST_FETCHING = "SET_LIST_FETCHING",
    SET_PREMIUM_FETCHING = "SET_PREMIUM_FETCHING",
    SET_ADVERTS = "SET_ADVERTS",
    SET_PREMIUM = " SET_PREMIUM",
    SET_SIMPLE_ADVERTS = "SET_SIMPLE_ADVERTS",
    SET_TOTAL = "SET_TOTAL",
    SET_PAGE = "SET_PAGE",
    SET_SORT_INDEX = "SET_SORT_INDEX",
    SET_LAST_VISITED = "SET_LAST_VISITED"
}

export interface SetListFetchingAction {
    type: ListActionTypes.SET_LIST_FETCHING
    payload: boolean
}

export interface SetPremiumFetchingAction {
    type: ListActionTypes.SET_PREMIUM_FETCHING
    payload: boolean
}

export interface SetAdvertsAction {
    type: ListActionTypes.SET_ADVERTS
    payload: Array<IAdvertShort>
}

export interface SetPremiumAction {
    type: ListActionTypes.SET_PREMIUM
    payload: Array<IAdvertShort>
}

export interface SetSimpleAdvertsAction {
    type: ListActionTypes.SET_SIMPLE_ADVERTS
    payload: Array<IAdvertShort>
}

export interface SetTotalAction {
    type: ListActionTypes.SET_TOTAL
    payload: number
}

export interface SetPageAction {
    type: ListActionTypes.SET_PAGE
    payload: number
}

export interface SetSortIndexAction {
    type: ListActionTypes.SET_SORT_INDEX
    payload: number
}

export interface SetLastVisitedAction {
    type: ListActionTypes.SET_LAST_VISITED,
    payload: Array<IAdvertShort>
}

export type ListActions =
    SetListFetchingAction |
    SetPremiumFetchingAction |
    SetAdvertsAction |
    SetPremiumAction |
    SetTotalAction |
    SetPageAction |
    SetSortIndexAction |
    SetSimpleAdvertsAction |
    SetLastVisitedAction
