import {ICategory, ICategoryRoot} from "../../models/ICategory";
import {IFilter} from "../../models/IFilter";
import {ITariff, ITariffType} from "../../models/ITariff";
import {IAdvertDetail} from "../../models/IAdvert";

export interface EditAdvertState {
    isFetching: boolean
    advert: IAdvertDetail
    data: any
    fields: Array<IFilter>
    location: any
    mapLocation: any
}

export enum EditAdvertTypes {
    SET_EDIT_ADVERT_FETCHING = "SET_EDIT_ADVERT_FETCHING",
    SET_EDIT_ADVERT_ADVERT = "SET_EDIT_ADVERT_ADVERT",
    SET_EDIT_ADVERT_DATA = "SET_EDIT_ADVERT_DATA",
    SET_EDIT_ADVERT_FIELDS = "SET_EDIT_ADVERT_FIELDS",
    SET_EDIT_ADVERT_LOCATION = "SET_EDIT_ADVERT_LOCATION",
    SET_EDIT_ADVERT_MAP_LOCATION = "SET_EDIT_ADVERT_MAP_LOCATION",
    CLEAR_EDIT_ADVERT_STATE = "CLEAR_EDIT_ADVERT_STATE"
}

export interface SetEditAdvertFetchingAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_FETCHING
    payload: boolean
}

export interface SetEditAdvertAdvertAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_ADVERT
    payload: IAdvertDetail
}

export interface SetEditAdvertDataAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_DATA
    payload: any
}

export interface SetEditAdvertFieldsAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_FIELDS
    payload: Array<IFilter>
}

export interface SetEditAdvertLocationAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_LOCATION
    payload: any
}

export interface SetEditAdvertMapLocationAction {
    type: EditAdvertTypes.SET_EDIT_ADVERT_MAP_LOCATION
    payload: any
}


export interface ClearEditAdvertState {
    type: EditAdvertTypes.CLEAR_EDIT_ADVERT_STATE
}

export type EditAdvertActions =
    SetEditAdvertFetchingAction |
    SetEditAdvertAdvertAction |
    SetEditAdvertDataAction |
    SetEditAdvertFieldsAction |
    SetEditAdvertLocationAction |
    SetEditAdvertMapLocationAction |
    ClearEditAdvertState