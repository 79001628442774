import {NewAdvertActions, NewAdvertState, NewAdvertTypes} from "./types";
import {ICategory, ICategoryRoot} from "../../models/ICategory";
import {ITariff} from "../../models/ITariff";
/**/
const initialState: NewAdvertState = {
    isFetching: false,
    data: {},
    rootCategories: [],
    activeRootCategory: {} as ICategoryRoot,
    categories: [],
    activeCategory: {} as ICategory,
    fields: [],
    location: null,
    mapLocation: null,
    mode: "main_premium",
    tariffTypes: [],
    selectedTariff: {} as ITariff,
    user: {
        advertDisplayName: "",
        advertEmail: "",
        advertPhone: ""
    }
}

const newAdvertReducer = (state: NewAdvertState = initialState, action: NewAdvertActions): NewAdvertState => {
    switch (action.type) {
        case NewAdvertTypes.SET_NEW_ADVERT_FETCHING: return {...state, isFetching: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_DATA: return {...state, data: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_ROOT_CATEGORIES: return {...state, rootCategories: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_ACTIVE_ROOT_CATEGORY: return {...state, activeRootCategory: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_CATEGORIES: return {...state, categories: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_ACTIVE_CATEGORY: return {...state, activeCategory: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_FIELDS: return {...state, fields: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_LOCATION: return {...state, location: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_MAP_LOCATION: return {...state, mapLocation: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_MODE: return {...state, mode: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_TARIFF_TYPES: return {...state, tariffTypes: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_TARIFF: return {...state, selectedTariff: action.payload}
        case NewAdvertTypes.SET_NEW_ADVERT_USER: return {...state, user: action.payload}
        case NewAdvertTypes.CLEAR_NEW_ADVERT_STATE: return initialState
        default: return state
    }
}

export default newAdvertReducer