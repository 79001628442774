import React, {DetailedHTMLProps, FC, HTMLAttributes} from 'react';
import s from "./AdminButton.module.scss"

type AdminButtonProps = {
    title: string
    style?: ButtonStyle
    type?: ButtonType
} & DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>


const AdminButton: FC<AdminButtonProps> = (
    {
        title,
        style = ButtonStyle.FILLED,
        type = ButtonType.PRIMARY,
        className,
        ...rest
    }
) => {

    return (
        <button className={`${s.container} ${s[type]} ${s[style]} ${className}`} {...rest}>
            {title}
        </button>
    );
};

export enum ButtonStyle {
    OUTLINE = "outline",
    FILLED = "filled",
    UNDERLINE = "underline"
}

export enum ButtonType {
    PRIMARY = "primary",
    DANGEROUS = "dangerous",
}

export default AdminButton;