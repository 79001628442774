import {MapAction, MapActionTypes, MapState} from "./types";
import {IAdvertShort} from "../../models/IAdvert";

const initialState: MapState = {
    isFetchingCoordinates: true,
    isFetchingPopupAdvert: false,
    isPopupShow: false,
    isExpand: false,
    popupAdvert: {} as IAdvertShort,
    coordinates: [],
    zoom: 4,
    center: {
        lat: 58.73479074268076,
        lng: 14.638577903931925,
    },
    map: null,
    formattedAddress: ""
}

export default function mapReducer(state: MapState = initialState, action: MapAction): MapState {
    switch (action.type) {
        case MapActionTypes.SET_FETCHING_COORDINATES: return {...state, isFetchingCoordinates: action.payload}
        case MapActionTypes.SET_FETCHING_POPUP_ADVERT: return {...state, isFetchingPopupAdvert: action.payload}
        case MapActionTypes.SET_COORDINATES: return {...state, coordinates: action.payload}
        case MapActionTypes.SET_POPUP_ADVERT: return {...state, popupAdvert: action.payload}
        case MapActionTypes.SET_POPUP_SHOW: return {...state, isPopupShow: action.payload}
        case MapActionTypes.SET_EXPAND: return {...state, isExpand: action.payload}
        case MapActionTypes.SET_CENTER: return {...state, center: action.payload}
        case MapActionTypes.SET_ZOOM: return {...state, zoom: action.payload}
        case MapActionTypes.SET_MAP: return {...state, map: action.payload}
        case MapActionTypes.SET_FORMATTED_ADDRESS: return {...state, formattedAddress: action.payload}
        default: return state
    }
}
