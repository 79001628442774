import {IAdvertDetail, IAdvertShort} from "../../models/IAdvert";

export interface AdvertState {
    isFetching: boolean
    data: IAdvertDetail
    similar: Array<IAdvertShort>
}

export enum AdvertActionTypes {
    SET_ADVERT_FETCHING = "SET_ADVERT_FETCHING",
    SET_ADVERT_DATA = "SET_ADVERT_DATA",
    SET_SIMILAR_ADVERTS = "SET_SIMILAR_ADVERTS"
}

export interface SetAdvertFetchingAction {
    type: AdvertActionTypes.SET_ADVERT_FETCHING
    payload: boolean
}

export interface SetAdvertDataAction {
    type: AdvertActionTypes.SET_ADVERT_DATA
    payload: IAdvertDetail
}

export interface SetSimilarAdvertsAction {
    type: AdvertActionTypes.SET_SIMILAR_ADVERTS
    payload: Array<IAdvertShort>
}

export type AdvertActions =
    SetAdvertFetchingAction |
    SetAdvertDataAction |
    SetSimilarAdvertsAction