import React, {
  CSSProperties,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  SyntheticEvent,
  useState,
} from "react";
import s from "./ContactUsForm.module.scss";
import FormInput from "../../FormInput/FormInput";
import CustomButton from "../../Button/CustomButton";
import useTranslation from "next-translate/useTranslation";
import { IContactFormData } from "../../../models/IContactFormData";
import { Icon } from "../../Icon/Icon";
import Link from "next/link";

const ContactUsForm: FC<TContactUsForm> = ({
  userData,
  handleDataChange,
  submit,
  error,
  disableButton
}): JSX.Element => {
  const { t } = useTranslation("common");

  const [policyChecked, setPolicyChecked] = useState<boolean>(false)

  const buttonStyles: CSSProperties = {
    paddingLeft: 81,
    paddingRight: 81,
    width: "auto",
    margin: "auto"
  };

  return (
    <form className={s.form} onSubmit={submit}>
      <div className={s.title}>
        <span>{t("Contact us")}</span>
      </div>
      <div className={s.description}>
        {t(
          "If you wish to contact Odin 24 for reach or add any service, kindly utilize the form provided below."
        )}
      </div>
      <div className={s.row}>
        <div className={`${s.block} ${error.length && !userData.name && s.withError}`}>
          <span className={s.text}>{t("Your name")} *</span>
          <div className={s.input}>
            <FormInput
              name={"name"}
              value={userData.name}
              onChange={(name, value) =>
                handleDataChange(name.toString(), value.toString())
              }
              placeholder={" "}
            />
          </div>
        </div>
        <div className={`${s.block} ${error.length && !userData.email && s.withError}`}>
          <span className={s.text}>{t("Your e-mail address")}*</span>
          <div className={s.input}>
            <FormInput
              name={"email"}
              value={userData.email}
              onChange={(name, value) =>
                handleDataChange(name.toString(), value.toString())
              }
              placeholder={" "}
              icon={{ prev: "email" }}
            />
          </div>
        </div>
        <div className={`${s.block} ${error.length && !userData.phone && s.withError}`}>
          <span className={s.text}>{t("Your telephone number")}*</span>
          <div className={s.input}>
            <FormInput
              name={"phone"}
              value={userData.phone}
              onChange={(name, value) =>
                handleDataChange(name.toString(), value?.toString())
              }
              placeholder={" "}
              type={"phone"}
            />
          </div>
        </div>
      </div>
      <div className={s.row}>
        <div className={`${s.block} ${error.length && !userData.type && s.withError}`}>
          <span className={s.text}>{t("Service’s type")}*</span>
          <div className={s.input}>
            <select
              className={s.select}
              value={!!userData?.type ? userData?.type : ""}
              onChange={(e) => {
                handleDataChange("type", e.target.value);
              }}
            >
              <option value="" disabled hidden>
                {t("Choose your type of service")}
              </option>
              <option value="advertising">{t("Advertising")}</option>
              <option value="collaboration">{t("Collaboration")}</option>
              <option value="complaint">{t("Complaint")}</option>
              <option value="recommendation">{t("Recommendation")}</option>
              <option value="payment">{t("Payment")}</option>
              <option value="other">{t("Other")}</option>
            </select>
          </div>
        </div>
        <div className={`${s.block} ${error.length && !userData.subject && s.withError}`}>
          <span className={s.text}>{t("Subject")}</span>
          <div className={s.input}>
            <FormInput
              name={"subject"}
              value={userData.subject}
              onChange={(name, value) =>
                handleDataChange(name.toString(), value.toString())
              }
              placeholder={" "}
            />
          </div>
        </div>
      </div>
      <div className={`${s.block} ${error.length && !userData.comments && s.withError}`}>
        <span className={s.text}>{t("Comments")}</span>
        <div className={s.input}>
          <textarea
            className={s.textarea}
            value={userData.comments}
            onChange={(e) => handleDataChange("comments", e.target.value)}
          />
        </div>
      </div>
      <div className={s.policy}>
        <div
          className={s.policy__checkbox}
          onClick={() => setPolicyChecked(!policyChecked)}
        >
          {policyChecked ? <Icon name={"checkbox-checked"} size={8} /> : ""}
        </div>
        <div className={s.policy__text}>
          {t("I accept the")}{" "}
          <Link href="/policy" className={s.policy__link} target="_blank">

            {t("Privacy Policy")}

          </Link>{" "}
          {t("and")}{" "}
          <Link href="/terms" className={s.policy__link} target="_blank">

            {t("Terms of Service")}

          </Link>
        </div>
      </div>
      {error && (
        <div className={s.error}>
          <div className={s.error_message}>{error}</div>
        </div>
      )}
      <CustomButton
        title={t("Send")}
        clickAction={() => {}}
        styles={buttonStyles}
        disabled={!policyChecked || disableButton}
        type="submit"
      />
    </form>
  );
};

type TContactUsForm = {
  userData: IContactFormData;
  handleDataChange: (name: string, value: string) => void;
  submit:  (e: SyntheticEvent) => void
  error:  string
  disableButton: boolean
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default ContactUsForm;
