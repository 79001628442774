import React, {
    CSSProperties,
    DetailedHTMLProps,
    FC,
    HTMLAttributes,
    SyntheticEvent,
    useEffect,
    useState,
} from 'react';
import s from './LoginForm.module.scss';
import FormInput from '../../FormInput/FormInput';
import Image from 'next/legacy/image';
import CustomButton from '../../Button/CustomButton';
import { useAuth } from '../../../context/AuthContext';
import useTranslation from 'next-translate/useTranslation';

const LoginForm: FC<TLoginForm> = ({
    visible,
    setVisible,
    isPopup,
    switchPopup,
    ...restProps
}): JSX.Element => {
    const [error, setError] = useState<string>('');
    const [authData, setAuthData] = useState({ email: '', password: '' });

    const auth = useAuth();
    const { t } = useTranslation('common');

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        let res = await auth.login(authData);
        setAuthData({
            email: '',
            password: '',
        });
        if (res) {
            setError(res.message);
        }
    };

    const buttonStyles: CSSProperties = {
        paddingLeft: 81,
        paddingRight: 81,
        width: 'auto',
        margin: 'auto',
        marginTop: 39,
    };

    useEffect(() => {
        if (!visible) {
            setError('');
        }
    }, [visible]);

    const [inputType, setInputType] = useState<'text' | 'password'>('password');
    const [passwordIcon, setPasswordIcon] = useState('password_hidden');

    const changeInput = () => {
        inputType === 'text' ? setInputType('password') : setInputType('text');
        passwordIcon === 'password_hidden'
            ? setPasswordIcon('password_show')
            : setPasswordIcon('password_hidden');
    };
    return (
        <div {...restProps} className={s.popup}>
            {isPopup ? (
                <div className={s.close} onClick={() => setVisible(false)}>
                    <Image
                        src={'/assets/img/auth/cross.png'}
                        objectFit={'cover'}
                        layout={'fill'}
                        alt={'close'}
                    />
                </div>
            ) : (
                ''
            )}
            <form className={s.form} onSubmit={submit}>
                <div className={s.title}>
                    <span>{t('Login')}</span>
                </div>
                <div className={s.block}>
                    <span className={s.text}>{t('E-mail')}</span>
                    <div className={s.input}>
                        <FormInput
                            name={'email'}
                            value={authData.email}
                            onChange={(name, value) => {
                                setAuthData((prev) => ({
                                    ...prev,
                                    email: value.toString(),
                                }));
                            }}
                            placeholder={' '}
                            icon={{ prev: 'email' }}
                        />
                    </div>
                </div>
                <div className={s.block}>
                    <span className={s.text}>{t('Password')}</span>
                    <div className={s.input}>
                        <FormInput
                            name={'password'}
                            type={inputType}
                            value={authData.password}
                            onChange={(name, value) => {
                                setAuthData((prev) => ({
                                    ...prev,
                                    password: value.toString(),
                                }));
                            }}
                            placeholder={' '}
                            icon={{
                                prev: 'password',
                                post: passwordIcon,
                                onClick: changeInput,
                            }}
                        />
                    </div>
                </div>
                {error && (
                    <div className={s.error}>
                        <div className={s.error_message}>{error}</div>
                    </div>
                )}

                <div className={s.message}>
                    <div
                        className={s.helpText}
                        onClick={() => switchPopup('forgot')}
                    >
                        {t('Forgot password?')}
                    </div>

                    <div
                        className={s.helpText}
                        onClick={() => switchPopup('signUp')}
                    >
                        {t('Don’t have an account?')}
                    </div>
                </div>
                <CustomButton
                    title={t('Login')}
                    clickAction={() => {}}
                    styles={buttonStyles}
                    type="submit"
                />
            </form>
        </div>
    );
};

type TLoginForm = {
    visible: boolean;
    setVisible: (value: boolean) => void;
    isPopup: boolean;
    switchPopup: (popupName: string) => void;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default LoginForm;
