import {createEntityAdapter} from "@reduxjs/toolkit";
import {IChat} from "../../models/IChat";
import {IChatMessage} from "../../models/IChatMessage";

export const chatAdapter = createEntityAdapter<IChat>({
    selectId: (chat) => chat.id,
    sortComparer: (chat1, chat2) => -1 * chat1.lastDate.localeCompare(chat2.lastDate)
})

export const messageAdapter = createEntityAdapter<IChatMessage>({
    selectId: (message) => message.id,
    sortComparer: (message1, message2) => message1.date.localeCompare(message2.date)
})