import React, {useEffect, useLayoutEffect} from 'react';
import {useRouter} from "next/router";
import {useAuth} from "../context/AuthContext";

const withAdminPrivileges = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const Wrapper = (props: React.FC<P>) => {
        const {user} = useAuth()
        const router = useRouter()
        
        useEffect(() => {
            if (!user?.role) {
                router.push("/");
            }
        }, [router, user])

        return <WrappedComponent {...props as P}/>
    }

    return Wrapper
};

export default withAdminPrivileges;
