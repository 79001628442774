import axios, {AxiosResponse} from "axios";
import {AdvertsListParams} from "./params";
import {
    AdvertResponse,
    AdvertsListResponse,
    CategoriesResponse,
    CoordinatesResponse,
    FilteredAdvertsListResponse,
    FiltersResponse,
    LoginResponse,
    MapPopupAdvertResponse, MetaResponse,
    SimpleAdvertsResponse, TariffsResponse,
    UserAdvertsResponse,
    UserResponse
} from "./response";
import IUser from "../models/IUser";
import {IRegisterLangData} from "../models/IRegisterData";
import {Agent} from "https";
import {backendUrl} from "../utils/url";
import {IChatMessage} from "../models/IChatMessage";
import {IChat} from "../models/IChat";
import getConfig from "next/config";

const {publicRuntimeConfig} = getConfig()

let httpAgent = new Agent();
if (process.env.NODE_ENV === "development") {
    httpAgent = new Agent({
        rejectUnauthorized: false
    });
}

const AxiosActions = {
    instance: axios.create({
        baseURL: publicRuntimeConfig.API_URL,
        withCredentials: true,
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json',
            'Accept-Language': "sv"
        },
        httpsAgent: httpAgent
    }),
    withAuth: (token) => {
        AxiosActions.instance = axios.create({
            baseURL: publicRuntimeConfig.API_URL,
            withCredentials: true,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                'Accept-Language': "sv",
                'Authorization': 'Bearer ' + token
            },
            httpsAgent: httpAgent
        })

        return AxiosActions
    },
    getMeta: (url: string): Promise<AxiosResponse<MetaResponse>> => {
        return AxiosActions.instance.get(`meta${url}`)
    },
    getRootMenu: () => {
        return AxiosActions.instance.get('categories')
    },
    getCategories: (categoryCode: string): Promise<AxiosResponse<CategoriesResponse>> => {
        return AxiosActions.instance.get(`category/${categoryCode}`)
    },
    getFilters: (categoryCode: string): Promise<AxiosResponse<FiltersResponse>> => {
        return AxiosActions.instance.get(`filter/${categoryCode}`)
    },
    getPremium: (root: string, mode: Array<"on_main_page" | "in_category">): Promise<AxiosResponse<AdvertsListResponse>> => {
        return AxiosActions.instance.get(`adverts/${root}`, {
            params: {
                premium: mode,
                limit: 9
            }
        })
    },
    getLastVisited: () => {
        return AxiosActions.instance.get("adverts" ,{
            params: {
                sort_field: 'visited_at',
                sort_order: 'desc',
                limit: 5
            }
        })
    },
    getSimilar: (params: AdvertsListParams): Promise<AxiosResponse<AdvertsListResponse>> => {
        return AxiosActions.instance.get(`adverts/${params.url}?limit=5`)
    },
    getAdvert: (slug: string, id: string): Promise<AxiosResponse<AdvertResponse>> => {
        return AxiosActions.instance.get(`advert/${slug}/${id}`)
    },
    setAdvertStatus: (slug: string, id: string, status: string, text: string = ''): Promise<AxiosResponse<AdvertResponse>> => {
        return AxiosActions.instance.post(`advert/${slug}/${id}`, {
            status,
            text
        })
    },
    cancelAdvertTariff :  (advertId: string, tariffId: string ): Promise<AxiosResponse<AdvertResponse>> => {
        return AxiosActions.instance.delete(`/admin/advert/${advertId}/tariff/${tariffId}`)
    },
    getUser: (id: string): Promise<AxiosResponse<UserResponse>> => {
        return AxiosActions.instance.get(`user/?user_id=${id}`)
    },
    getAdverts: (body: any, params: AdvertsListParams): Promise<AxiosResponse<FilteredAdvertsListResponse>> => {
        return AxiosActions.instance.post(
            'advert/filter', {...body, ...params })
    },
    getSimpleAdverts: (): Promise<AxiosResponse<SimpleAdvertsResponse>> => {
        return AxiosActions.instance.get('adverts/')
    },
    getUserAdverts: (token: string): Promise<AxiosResponse<UserAdvertsResponse>> => {
        return AxiosActions.instance.get(`user/adverts/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getCoordinates: (url: string): Promise<AxiosResponse<CoordinatesResponse>> => {
        return AxiosActions.instance.get(`coordinate/${url}`)
    },
    getMapPopupAdvert: (id: number): Promise<AxiosResponse<MapPopupAdvertResponse>> => {
        return AxiosActions.instance.get(`advert/preview/${id}`)
    },
    login: async (authData: { email: string, password: string }): Promise<AxiosResponse<LoginResponse>> => {
        return AxiosActions.instance.post(`login`, authData)
    },
    logout: async (token: string): Promise<AxiosResponse<null>> => {
        return AxiosActions.instance.get(`logout`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    register: (registerData: IRegisterLangData): Promise<AxiosResponse<{ userId: number }>> => {
        return AxiosActions.instance.post(`register`, registerData)
    },
    me: (): Promise<AxiosResponse<IUser>> => {
        return AxiosActions.instance.get(`me`)
    },
    verifyEmail: (id: string, hash: string, signature: string, verifyEmail: string): Promise<AxiosResponse<null>> => {
        return AxiosActions.instance.get(`verify-email`, {params: {hash, id,verifyEmail, signature }})
    },
    getFavorites: (token: string): Promise<AxiosResponse> => {
        return AxiosActions.instance.get("favorite", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getFavoritesIds: (token: string): Promise<AxiosResponse> => {
        return AxiosActions.instance.get("favorite/ids", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    setFavoriteAdvert: (advert_id: any, token: string): Promise<AxiosResponse> => {
        return AxiosActions.instance.post("favorite", {advert_id}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    unsetFavoriteAdvert: (advert_id: any, token: string): Promise<AxiosResponse> => {
        return AxiosActions.instance.delete("favorite?advert_id=" + advert_id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getChats: (token: string, page: number = 0): Promise<AxiosResponse<IChat[]>> => {
        return AxiosActions.instance.get(`chats`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getMessages: (token: string, chat_id: number, lastMessageId: number | null = null): Promise<AxiosResponse<IChatMessage[]>> => {
        return AxiosActions.instance.get(`messages`, {
            params: {
                chat_id,
                last_message_id: lastMessageId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    sendMessage: (token: string, chat_id: number, message: string) => {
        return AxiosActions.instance.post(`messages/create`, {
            chat_id,
            message
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    createChat: (token: string, advertId: number) => {
        return AxiosActions.instance.post(
            `chats/create`,
            {
                advert_id: advertId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
    },
    readMessages: (token: string, chat_id: number, firstMessageId: number) => {
        return AxiosActions.instance.post(`messages/read`,
            {
                chat_id,
                first_message_id: firstMessageId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
    },

    getTariffs: (ids: Array<number>): Promise<AxiosResponse<TariffsResponse>> => {
        return AxiosActions.instance.get("tariffs", {
            params: {
                category_id: ids
            }
        })
    },
    getAdvertToEdit: (token: string, id: string) => {
        return AxiosActions.instance.get(`advert/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },

    deleteAdvert: (token: string, id: number) => {
        return AxiosActions.instance.delete(`advert/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    searchAutocomplete: (s: string) => {
        return AxiosActions.instance.get(
            backendUrl('/autocomplete'),
            {
                params: {s}
            }
        )
    },
    deleteChat(token: string, chatId: number) {
        return AxiosActions.instance.delete(`chats/${chatId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getBaseFilters() {
        return AxiosActions.instance.get(`filterstosearch`)
    },
    getAdminCategories(token: string) {
        return AxiosActions.instance.get(`admin/categories`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getMe(token: string): Promise<AxiosResponse<IUser>> {
        return AxiosActions.instance.get(`me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
}

export default AxiosActions
