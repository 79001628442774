import s from './CustomButton.module.scss';
import { CSSProperties } from 'react';

export type ButtonProps = {
    title: string;
    disabled?: boolean;
    clickAction: () => void;
    styles?: CSSProperties;
    variant?: 'primary' | 'secondary' | 'critical';
    type?: 'submit' | 'button';
};

const CustomButton = (props: ButtonProps) => {
    return (
        <button
            onClick={props.clickAction}
            className={`${s.button} ${s[props.variant] || ""}`}
            disabled={props.disabled}
            style={props.styles || {}}
            type={props.type || 'button'}
        >
            {props.title}
        </button>
    );
};

export default CustomButton;
